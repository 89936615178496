import React from 'react'; // eslint-disable-line

import { Grid, Button, Tooltip } from '@material-ui/core';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@material-ui/icons';
import axios from 'axios';
import { openDialogAction } from '../../../../../confirmation-dialog';
import { env } from '../../../../../env';
import { openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { hideLoadingAction, showLoadingAction } from '../../../../../forms/loading-screen/actions';

export function askSynopsisType(claimId: string) {
    return async (dispatch: any) => {
        dispatch(
            openDialogAction({
                content: {
                    title: 'Select synopsis type',
                    body: 'Is this synopsis for underwriting?',
                },
                noAction: { action: async () => await getSynopsisLink(claimId, dispatch, false) },
                yesAction: { action: async () => await getSynopsisLink(claimId, dispatch, true) },
                canCancel: true,
            })
        );
    };
}

export function trusteeResolution(claimId: string) {
    return async (dispatch: any) => {
        await getSynopsisLink(claimId, dispatch, false, true);
    };
}

async function getSynopsisLink(
    claimId: string,
    dispatch: any,
    forUnderwriting: boolean,
    isTrusteeResolution?: boolean
) {
    dispatch(showLoadingAction());
    let generateSynopsisUri = `${env().CLAIMS_API}/api/Claim/${claimId}/synopsis`;
    if (forUnderwriting) {
        generateSynopsisUri += '?forUnderwriting=true';
    }
    await axios
        .get(generateSynopsisUri)
        .then(response => {
            if (response.status === 200) {
                dispatch(
                    openModalAction({
                        modalContent: (
                            <FormModalView
                                formView={() => (
                                    <SynopsisPdfView
                                        claimId={claimId}
                                        dispatch={dispatch}
                                        url={response.data}
                                        underwriting={forUnderwriting || false}
                                        isTrusteeResolution={isTrusteeResolution}
                                    />
                                )}
                                closeButton
                            />
                        ),
                    })
                );
            } else {
                showErrorMessage(`Status Code ${response.status}`);
            }
        })
        .catch(error => {
            let errorMessage = '';
            if (error.response) {
                errorMessage = `Error Code ${error.response.status}`;
            } else {
                errorMessage = error.message;
            }
            showErrorMessage(errorMessage);
        })
        .then(() => {
            dispatch(hideLoadingAction());
        });

    function showErrorMessage(error: any) {
        const errorMessage = `Synopsis could not be generated: ${error}`;
        dispatch(
            openDialogAction({
                content: {
                    title: 'Error generating synopsis',
                    body: errorMessage,
                    error: true,
                },
            })
        );
    }
}

interface Props {
    claimId: string;
    dispatch: any;
    url: string;
    underwriting: boolean;
    isTrusteeResolution?: boolean;
}

const SynopsisPdfView: React.FunctionComponent<Props> = ({
    claimId,
    dispatch,
    isTrusteeResolution,
    url,
    underwriting,
}: Props) => {
    const documentName = isTrusteeResolution ? 'trustee resolution' : 'synopsis';
    return (
        <Grid container justify="space-between">
            <Grid item xs={6}>
                <Tooltip title="Download pdf">
                    <Button
                        href={url}
                        id="downloadPdf"
                        color="secondary"
                        style={{ padding: 5, textTransform: 'none' }}
                    >
                        <CloudDownloadOutlined style={{ marginRight: 8 }} />
                        Download {documentName}
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={6}>
                <Tooltip title="Upload to Horizon">
                    <Button
                        id="uploadPdf"
                        color="secondary"
                        style={{ padding: 5, textTransform: 'none' }}
                        onClick={() =>
                            dispatch(
                                uploadDocumentAction({
                                    claimId: claimId,
                                    url: url,
                                    underwriting: underwriting,
                                })
                            )
                        }
                    >
                        <CloudUploadOutlined style={{ marginRight: 8 }} />
                        Upload {documentName}
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

interface UploadDocumentRequest {
    claimId: string;
    url: string;
    underwriting: boolean;
}

function uploadDocumentAction(request: UploadDocumentRequest) {
    return async (dispatch: any) => {
        dispatch(showLoadingAction());
        const uploadSynopsisUri = `${env().CLAIMS_API}/api/Claim/${
            request.claimId
        }/UploadSynopsis?underwriting=${request.underwriting ? 'true' : 'false'}`;

        await axios
            .post(uploadSynopsisUri, getUploadRequest(request))
            .then(response => {
                if (response.status === 200) {
                    dispatch(
                        openDialogAction({
                            content: {
                                title: 'Synopsis uploaded successfully',
                                body: `Document ID: ${response.data.documentId}`,
                            },
                        })
                    );
                } else {
                    showErrorMessage(`Status Code ${response.status}`);
                }
            })
            .catch(error => {
                let errorMessage = '';
                if (error.response) {
                    errorMessage = `Error Code ${error.response.status}`;
                } else {
                    errorMessage = error.message;
                }
                showErrorMessage(errorMessage);
            })
            .then(() => dispatch(hideLoadingAction()));

        function showErrorMessage(error: any) {
            const errorMessage = `An error (${error}) occured while uploading synopsis, please retry later`;
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error uploading synopsis',
                        body: errorMessage,
                        error: true,
                    },
                })
            );
        }
    };
}

function getUploadRequest(request: UploadDocumentRequest) {
    const name = request.url.substring(request.url.lastIndexOf('/') + 1, request.url.indexOf('?'));
    return {
        name,
        url: request.url,
        underwriting: request.underwriting,
    };
}
