import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { ClaimType } from '../../../../../claim/claim-types';
import { BenefitCategory, BenefitCategoryDread } from '../../../../../codes/benefit-category';
import { ClaimConditions, ClaimConditionsDread } from '../../../../../codes/claim-condition';
import { CurrentTreatment } from '../../../../../codes/claim-event';
import { Conditions } from '../../../../../codes/conditions';
import {
    DetailedClaimConditions,
    DetailedClaimConditionsDread,
} from '../../../../../codes/detailed-claim-condition';
import { MajorClaimCause } from '../../../../../codes/major-claim-cause';
import {
    Condition,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    validations,
    WhenFieldChanges,
} from '../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    TelephoneNumberMask,
} from '../../../../../forms/input-masks';
import { requiredValidator } from '../../../../../forms/validations';
import {
    maxDateValidator,
    minDateValidator,
} from '../../../../../forms/validations/date-validator';
import { State } from '../../../../../redux/root-reducer';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';

type Props = StyledComponent & {
    claimType: Nullable<ClaimType>;
    heading: React.ReactNode;
    showTreatingSpecialist?: boolean;
};
const claimDetailsForm: React.FunctionComponent<Props> = ({
    claimType,
    classes,
    heading,
    showTreatingSpecialist,
}: Props) => {
    const { categoryOptions, claimConditionsOptions, detailedClaimConditions } = getClaimOptions(
        claimType
    );
    return (
        <React.Fragment>
            <Typography variant="h5">{heading} </Typography>
            <Divider></Divider>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="dateOfEvent"
                        validate={validations.composeValidators(
                            validations.requiredValidator,
                            maxDateValidator('dateClaimReported')
                        )}
                        maxDateField="dateClaimReported"
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="dateClaimReported"
                        validate={validations.composeValidators(
                            validations.requiredValidator,
                            minDateValidator('dateOfEvent')
                        )}
                        minDateField="dateOfEvent"
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="medicalConditionType" validate={requiredValidator}>
                        <RadioOption value="Disease" />
                        <RadioOption value="Accident" />
                    </FullWidthRadioGroup>
                    <WhenFieldChanges
                        field="medicalConditionType"
                        set="condition"
                        to={(type: string) => (type === 'Disease' ? 'Natural' : null)}
                    />
                </Grid>
                <Condition when="medicalConditionType" is="Accident">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthRadioGroup
                                label="Accident reported to the police"
                                name="reportedToPolice"
                                validate={requiredValidator}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                    </True>
                </Condition>
                <WhenFieldChanges field="medicalConditionType" set="reportedToPolice" to={null} />
                <WhenFieldChanges field="reportedToPolice" set="caseNumber" to={null} />
                <WhenFieldChanges field="reportedToPolice" set="accidentDetails" to={null} />
                <WhenFieldChanges field="reportedToPolice" set="policeStation" to={null} />
                <Condition when="reportedToPolice" is="Yes">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField name="policeStation" />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField name="caseNumber" />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="accidentDetails"
                                label="Accident details"
                                multiline
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Cause of Claim
                    </Typography>
                </Grid>
                <Field
                    name="medicalConditionType"
                    subscription={{ value: true }}
                    component={({ input: { value: medicalConditionType } }) => {
                        let options = Conditions.filter(x => x.value !== 'Natural');
                        let disabled = false;
                        if (medicalConditionType === 'Disease') {
                            disabled = true;
                            options = Conditions;
                        }
                        return (
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    label="Natural/Unnatural"
                                    name="condition"
                                    options={options}
                                    validate={requiredValidator}
                                    disabled={disabled}
                                />
                            </Grid>
                        );
                    }}
                />
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        label="Major claim cause"
                        name="majorClaimCause"
                        helperText="Current claim diagnosis"
                        options={MajorClaimCause}
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField name="claimCategory" options={categoryOptions} />
                </Grid>
                <Condition when="claimCategory" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="claimCategoryDesc"
                                label="Claim category (free text)"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="claimCondition"
                        options={claimConditionsOptions}
                    />
                </Grid>
                <Condition when="claimCondition" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="claimConditionDesc"
                                label="Claim condition (free text)"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="detailedClaimCondition"
                        options={detailedClaimConditions}
                        validate={requiredValidator}
                    />
                </Grid>
                <Condition when="detailedClaimCondition" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="detailedClaimConditionDesc"
                                label="Detailed claim condition (free text)"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField label="Comments on cause" name="comments" multiline />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Treatment
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="currentTreatmentPlan"
                        label="Current treatment plan"
                        options={CurrentTreatment}
                    />
                </Grid>
                <WhenFieldChanges
                    field="currentTreatmentPlan"
                    becomes="Other"
                    set="highLevelDescriptionOfTreatmentOther"
                    to={null}
                />
                <Condition when="currentTreatmentPlan" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                label="High-level description of the current treatment plan"
                                name="highLevelDescriptionOfTreatmentOther"
                                validate={requiredValidator}
                                multiline
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Detailed description of current treatment Plan"
                        name="descriptionOfCurrentTreatmentPlan"
                        multiline
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Detailed description of Current Medication"
                        name="descriptionOfCurrentMedication"
                        multiline
                    />
                </Grid>
                {showTreatingSpecialist && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.marginTop}>
                                Treating Specialist
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistInitials"
                                mask={InitialsMask}
                                inputProps={{
                                    style: { textTransform: 'uppercase' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistName"
                                validate={validations.requiredValidator}
                                mask={FirstNameMask}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistSurname"
                                validate={validations.requiredValidator}
                                mask={LastNameMask}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="treatingSpecialistTelephoneNumber"
                                mask={TelephoneNumberMask}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
};

function getClaimOptions(claimType: Nullable<ClaimType>) {
    if (claimType === ClaimType.Dread) {
        return {
            categoryOptions: BenefitCategoryDread,
            claimConditionsOptions: ClaimConditionsDread,
            detailedClaimConditions: DetailedClaimConditionsDread,
        };
    }

    return {
        categoryOptions: BenefitCategory,
        claimConditionsOptions: ClaimConditions,
        detailedClaimConditions: DetailedClaimConditions,
    };
}

const mapStateToProps = (state: State) => ({
    claimType: getClaim(state).claimType,
});

export const ClaimDetailsForm = connect(mapStateToProps, {})(withStyles(styles)(claimDetailsForm));
