import React from 'react'; // eslint-disable-line

import {
    Chip,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Theme,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import { createStyles, StyleRules, WithStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import Select, {
    MultiValueProps,
    ControlProps,
    OptionProps,
    ValueContainerProps,
    SelectComponentsConfig,
} from 'react-select';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../../';
import { NoticeProps } from 'react-select/src/components/Menu';

function styles(theme: Theme): StyleRules {
    return createStyles({
        root: {
            flexGrow: 1,
            height: 250,
        },
        input: {
            display: 'flex',
            width: '100%',
            marginTop: '-2px',
            marginLeft: '4px',
        },
        chip: {
            margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
        },
        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
                0.08
            ),
        },
        noOptionsMessage: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        },
    });
}

export interface SelectOption {
    value: string;
    label: string;
}

type SelectProps = FieldRenderProps<any, any> &
    WithStyles<typeof styles> & {
        id?: string;
        label?: string;
        disabled?: boolean;
        helperText?: React.ReactNode;
        options: SelectOption[];
        matchPos?: string;
        sortOptions?: boolean;
        hideLabel?: boolean;
        onChange?: (values?: any) => void;
    };

type ComponentProps = MultiValueProps<SelectOption>;

const NoOptionsMessage: React.FunctionComponent<NoticeProps<any>> = (props: NoticeProps<any>) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};

const inputComponent: React.FunctionComponent<InputBaseComponentProps> = ({
    inputRef,
    ...props
}: InputBaseComponentProps) => {
    // @ts-ignore
    return <div ref={inputRef} {...props} />;
};

function Control({
    label,
    showError,
    value,
}: {
    label: React.ReactNode;
    showError: boolean;
    value: any;
}) {
    return function _Control(props: ControlProps<any>) {
        if (!value && props.hasValue) {
            props.clearValue();
        }
        return (
            <TextField
                fullWidth
                label={label}
                error={showError}
                InputLabelProps={{ shrink: value === '' ? undefined : true }}
                className={props.selectProps.classes.inputContainer}
                InputProps={{
                    inputComponent,
                    inputProps: {
                        className: props.selectProps.classes.input,
                        ref: props.innerRef,
                        children: props.children,
                        ...props.innerProps,
                    },
                }}
            />
        );
    };
}

const Option: React.ComponentType<OptionProps<any>> = (props: any) => {
    return (
        <Tooltip title={props.value}>
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                }}
                {...props.innerProps}
            >
                {props.children}
            </MenuItem>
        </Tooltip>
    );
};

const ValueContainer: React.FunctionComponent<ValueContainerProps<any>> = (
    props: ValueContainerProps<any>
) => {
    return (
        <Grid container spacing={1}>
            {props.children}
        </Grid>
    );
};

function MultiValue(props: ComponentProps) {
    return (
        <Grid item>
            <Chip
                color="secondary"
                variant="outlined"
                tabIndex={-1}
                label={props.children}
                className={classNames({
                    [props.selectProps.classes.chipFocused]: props.isFocused,
                })}
                onDelete={event => {
                    props.removeProps.onClick(event);
                    props.removeProps.onMouseDown(event);
                }}
            />
        </Grid>
    );
}

function _SearchSelectField(props: SelectProps) {
    const {
        input,
        meta,
        id,
        label,
        helperText,
        options,
        matchPos,
        onChange,
        disabled,
        sortOptions = true,
        hideLabel,
        ...rest
    } = props;
    const { name, value } = input;

    const { errorMessage, showError } = processMetaForErrors(meta);
    const components: SelectComponentsConfig<any> = {
        Option,
        Control: Control({
            label: hideLabel ? '' : nameToLabel({ label, name }),
            showError,
            value,
        }),
        NoOptionsMessage,
        MultiValue,
        ValueContainer,
    };
    const sortedOptions = sortOptions ? optionSort(options) : options;

    return (
        <React.Fragment>
            <Select
                {...rest}
                id={getComponentId({ id, name })}
                name={name}
                isDisabled={disabled}
                options={sortedOptions}
                matchPos={matchPos}
                components={components}
                value={transformFromValue(sortedOptions, value)}
                // @ts-ignore
                onChange={(op: SelectOption) =>
                    callAllFunctions(onChange, input.onChange)(transformToValue(op))
                }
                placeholder=""
                isClearable
            />
            {(showError || !!helperText) && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </React.Fragment>
    );
}

function transformFromValue(options: SelectOption[], value: any): SelectOption {
    if (!options) {
        return options;
    }
    return options.filter(op => op.value === value)[0];
}

function transformToValue(option?: SelectOption): any {
    return (option || { value: undefined }).value;
}

function sortWithLastItemAs(lastItem: string) {
    return function specialSorter(a: { label: string }, b: { label: string }) {
        // if lastItem, then ensure it appears *last*
        if (a.label === lastItem) return 1;
        if (b.label === lastItem) return -1;
        return a.label.localeCompare(b.label);
    };
}

function optionSort(options: SelectOption[]) {
    if (!options) {
        return options;
    }

    if (options.findIndex(item => item.label === 'Other') > 0)
        return options.sort(sortWithLastItemAs('Other'));

    if (options.findIndex(item => item.label === 'None') > 0)
        return options.sort(sortWithLastItemAs('None'));
    else return options.sort(sortWithLastItemAs(''));
}

export const SearchSelectField = withStyles(styles)(_SearchSelectField);
