import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { PostUnderwritingChangesView } from '../post-underwriting-changes/post-underwriting-changes-view';
import { UnderwritingDecisionsView } from '../underwriting-decisions';
import { UnderwritingDisclosuresView } from '../underwriting-disclosures';
import { UnderwritingExlusionsView } from '../underwriting-exclusions';
import { UnderwritingNonDisclosuresView } from '../underwriting-non-disclosures';
import { UnderwritingPolicyDetailsView } from '../underwriting-policy-details';
import { UnderwritingSpecialOffersView } from '../underwriting-special-offers';
import { UnderwritingDetails } from './underwriting-details-model';

interface OwnProps {
    policyIndex: number;
    underwritingDetails: UnderwritingDetails;
}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingDetailsView: React.FunctionComponent<Props> = ({
    policyIndex,
    underwritingDetails,
}: Props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <UnderwritingPolicyDetailsView
                    policyIndex={policyIndex}
                    policyDetails={underwritingDetails.policyDetails}
                    requirements={underwritingDetails.requirements}
                />
            </Grid>
            <Grid item xs={12}>
                <UnderwritingSpecialOffersView specialOffers={underwritingDetails.specialOffers} />
            </Grid>
            <Grid item xs={12}>
                <UnderwritingExlusionsView
                    benefitExclusions={underwritingDetails.benefitExclusions}
                />
            </Grid>
            <Grid item xs={12}>
                <UnderwritingDisclosuresView disclosures={underwritingDetails.disclosures} />
            </Grid>
            <Grid item xs={12}>
                <UnderwritingDecisionsView decisions={underwritingDetails.decisions} />
            </Grid>
            <Grid item xs={12}>
                <UnderwritingNonDisclosuresView
                    policyIndex={policyIndex}
                    nonDisclosures={underwritingDetails.nonDisclosures}
                />
            </Grid>
            <Grid item xs={12}>
                <PostUnderwritingChangesView
                    policyIndex={policyIndex}
                    postUnderwritingChanges={underwritingDetails.postUnderwritingChanges}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingDetailsView));
