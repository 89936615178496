import { AnyAction, Reducer } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { AddressDetails } from '../../../../forms/sub-forms/address-details';
import {
    defaultLifeAssuredDetails,
    LifeAssuredDetails,
} from '../../../../life-assured-details/reducer';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { simplePropertyReducer } from '../../../../redux/simple-property-reducer';
import { Nullable } from '../../../../shared-types';
import { ADD_DOCTOR_RECORD, DELETE_DOCTOR_RECORD, UPDATE_DOCTOR_RECORD } from './actions/types';

export interface Consultation {
    readonly id?: string;
    readonly date?: Date;
    readonly reason?: string;
}

export interface Doctor extends AddressDetails {
    readonly id?: string;
    readonly initials?: string;
    readonly name?: string;
    readonly surname?: string;
    readonly telephoneNumber?: string;
    doctorsVisits?: Consultation[];
}

export interface MedicalHistory {
    readonly doctorsDetails: Doctor[];
    readonly firstConsultationDate?: Nullable<Date>;
}

export interface OccupationDetails {
    readonly occupationAtApplication?: string;
    readonly isDeceasedEmployed?: string;
    readonly deceasedOccupation?: string;
    readonly deceasedEmployer?: string;
}

export interface DeathClaimLifeAssured extends LifeAssuredDetails {
    readonly occupationDetails: OccupationDetails;
    readonly medicalHistory: MedicalHistory;
}

export const defaultDeathClaimLifeAssured: DeathClaimLifeAssured = {
    ...defaultLifeAssuredDetails,
    occupationDetails: {},
    medicalHistory: { doctorsDetails: [] },
};

export const deathClaimFirstConsultaionDateReducer: Reducer<Nullable<Date>, AnyAction> = (
    state: Nullable<Date> = null,
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const defaultMedicalHistory: MedicalHistory = { doctorsDetails: [] };

const doctorRecordReducer = collectionReducer<Doctor>({
    addAction: ADD_DOCTOR_RECORD,
    deleteAction: DELETE_DOCTOR_RECORD,
    updateAction: UPDATE_DOCTOR_RECORD,
});

export const deathMedicalHistoryReducer = combineReducersWithDefault<MedicalHistory>(
    defaultMedicalHistory,
    {
        doctorsDetails: doctorRecordReducer,
        firstConsultationDate: deathClaimFirstConsultaionDateReducer,
    }
);
export const deathClaimLifeAssuredDetailsReducer = combineReducersWithDefault<
    Nullable<DeathClaimLifeAssured>
>(defaultDeathClaimLifeAssured, {
    personalDetails: simplePropertyReducer(defaultDeathClaimLifeAssured.personalDetails),
    residentialAddressDetails: simplePropertyReducer(
        defaultDeathClaimLifeAssured.residentialAddressDetails
    ),
    postalAddressDetails: simplePropertyReducer(defaultDeathClaimLifeAssured.postalAddressDetails),
    contactDetails: simplePropertyReducer(defaultDeathClaimLifeAssured.contactDetails),
    medicalAidDetails: simplePropertyReducer(defaultDeathClaimLifeAssured.medicalAidDetails),
    claimLifeNotifications: simplePropertyReducer(
        defaultDeathClaimLifeAssured.claimLifeNotifications
    ),
    contracts: simplePropertyReducer(defaultDeathClaimLifeAssured.contracts),
    previousPayments: simplePropertyReducer(defaultDeathClaimLifeAssured.previousPayments),
    originalDoctors: simplePropertyReducer(defaultDeathClaimLifeAssured.originalDoctors),
    occupationDetails: simplePropertyReducer({}),
    medicalHistory: deathMedicalHistoryReducer,
});

export function getDeathClaimLifeAssuredDetails(state: State): DeathClaimLifeAssured {
    return getDeathClaimLifeAssuredDetailsFromClaim(getClaim(state));
}

export function getDeathClaimLifeAssuredDetailsFromClaim(claim: Claim): DeathClaimLifeAssured {
    return getPropertyFromClaim(claim, 'lifeAssuredDetails', defaultDeathClaimLifeAssured);
}

export function getOccupationDetails(state: State): OccupationDetails {
    return getDeathClaimLifeAssuredDetails(state).occupationDetails || {};
}

export function getMedicalDoctorHistoryDetails(state: State): MedicalHistory {
    return getDeathClaimLifeAssuredDetails(state).medicalHistory;
}
