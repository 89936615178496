import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import {
    getLifeAssuredDetails,
    LifeAssuredDetails,
} from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { BenefitDetails } from './benefit-details';
import { PolicyDetailsView } from './policy-details';
import { PolicyOwnersView } from '../policy-owner/views/policy-owners';

interface Props extends StyledComponent {
    lifeAssuredDetails: LifeAssuredDetails;
}

const policySummaryView: React.FunctionComponent<Props> = ({
    classes,
    lifeAssuredDetails,
}: Props) => {
    if (!lifeAssuredDetails.contracts) {
        return null;
    }
    return (
        <ExpansionPanel name="Policy Details">
            {lifeAssuredDetails.contracts.map((contract, contractIndex) => {
                const panelName = `Policy ${contractIndex + 1} - ${contract.contractNumber}`;
                return (
                    <ExpansionPanel
                        name={panelName}
                        label={panelName}
                        key={`policy${contractIndex}`}
                        style={{ marginLeft: -24, marginRight: -24 }}
                    >
                        <PolicyDetailsView classes={classes} contract={contract} />
                        <PolicyOwnersView contract={contract} />
                        <BenefitDetails
                            classes={classes}
                            benefits={contract.benefits || []}
                            contractIndex={contractIndex}
                        />
                    </ExpansionPanel>
                );
            })}
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    lifeAssuredDetails: getLifeAssuredDetails(state),
});

export const PolicySummaryView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(policySummaryView));
