import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../styles/styles';
import { ClaimantDetailsView } from './views/claimant-details/claimant-details-view';
import { LifeAssuredDetailsView } from './views/life-assured-details/life-assured-details-view';
import { PolicyHolderDetailsView } from './views/policy-holder-details/policy-holder-view';
import { PaymentDetailsView } from './views/payment-details/payment-details-view';
import { connect } from 'react-redux';
import { State } from '../../../../redux/root-reducer';
import { ClaimReference, getReferences } from '../../../../claim/reducers/claim-reference-reducer';
import { FormDetails, getFormDetails, getFormDetailsList } from './form-details-reducer';

interface Props extends StyledComponent {
    lifeAssuredDetails: FormDetails[];
    lifeAssuredForm: FormDetails;
    isUnderWriting?: boolean;
    references: ClaimReference[];
}

const formDetailsView: React.FunctionComponent<Props> = ({
    classes,
    lifeAssuredDetails,
    lifeAssuredForm,
    isUnderWriting,
    references,
}: Props) => {
    return (
        <>
            {lifeAssuredDetails.length > 0 && (
                <ExpansionPanel name="Form Details">
                    {references.map((reference, referenceIndex) => {
                        const panelName = `Form ${referenceIndex + 1} - Case ${
                            reference.referenceId
                        }`;

                        const formDetail =
                            references.length === 1
                                ? lifeAssuredForm
                                : lifeAssuredDetails[referenceIndex];

                        if (
                            formDetail &&
                            formDetail.lifeAssured &&
                            formDetail.lifeAssured?.medicalAidDetails
                        ) {
                            formDetail.lifeAssured.medicalAidDetails.hasMedicalAid =
                                formDetail.lifeAssured?.medicalAidDetails
                                    ?.lifeAssuredMedicalAidNumber ||
                                formDetail.lifeAssured?.medicalAidDetails?.lifeAssuredMedicalAidName
                                    ? 'Yes'
                                    : 'No';
                        }

                        return (
                            <ExpansionPanel
                                name={panelName}
                                label={panelName}
                                key={`form${referenceIndex}`}
                                style={{ marginLeft: -16, marginRight: -16 }}
                            >
                                <ClaimantDetailsView
                                    formDetails={formDetail}
                                    disableEditButton={lifeAssuredDetails.length === 1}
                                />
                                <PolicyHolderDetailsView
                                    formDetails={formDetail}
                                    disableEditButton={lifeAssuredDetails.length === 1}
                                />
                                <LifeAssuredDetailsView
                                    formDetails={formDetail}
                                    disableEditButton={lifeAssuredDetails.length === 1}
                                />
                                <PaymentDetailsView
                                    formDetails={formDetail}
                                    disableEditButton={lifeAssuredDetails.length === 1}
                                />
                            </ExpansionPanel>
                        );
                    })}
                </ExpansionPanel>
            )}
        </>
    );
};

const mapStateToProps = (state: State) => ({
    lifeAssuredDetails: getFormDetailsList(state),
    lifeAssuredForm: getFormDetails(state),
    references: getReferences(state),
});

export default connect(mapStateToProps, {})(withStyles(styles)(formDetailsView));
