import React from 'react'; // eslint-disable-line

import { ContactPersonView } from '../common/contact-person';
import { DisabilityDetailsView } from '../common/disability-details';
import { ForensicInputView } from '../common/forensic-input';
import { LifeAssuredView } from '../common/life-assured';
import { MedicalOfficerInputView } from '../common/medical-officer-input';
import { PolicySummaryView } from '../common/policy-summary';
import { ReinsuranceInputView } from '../common/reinsurance-input';
import { RetrospectiveUnderwritingDecisionView } from '../common/retrospective-underwriting-decision';
import { Synopsis } from '../common/synopsis/synopsis';
import { UnderwritingView } from '../common/underwriting';
import { InformationRecordView } from '../common/information-record';
import { PolicyOwnerView } from '../common/policy-owner/views/policy-owner-v1';
import { AdditionalInformationRequestedView } from '../common/additional-information-requested';
import { InformationReceivedView } from '../common/information-received';
import { ClaimEventDetailsView } from '../common/claim-details/views/claim-event-details';
import FormDetailsView from '../living-lifestyle/form-details/form-details';
export const DisabilitySynopsis = () => {
    return (
        <Synopsis>
            <ContactPersonView />
            <PolicyOwnerView />
            <LifeAssuredView />
            <ClaimEventDetailsView
                causeOfClaimLabel="Current Claim Diagnosis"
                showTreatingSpecialist={true}
            />
            <DisabilityDetailsView />
            <PolicySummaryView />
            <InformationReceivedView />
            <AdditionalInformationRequestedView />
            <InformationRecordView />
            <MedicalOfficerInputView />
            <UnderwritingView />
            <FormDetailsView />
            <RetrospectiveUnderwritingDecisionView />
            <ForensicInputView />
            <ReinsuranceInputView />
        </Synopsis>
    );
};
