import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { Benefit } from '../../../../life-assured-details/reducer';
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { ReassuranceDetailsView } from './reassurance-details';
import { ReassuranceEditModal } from './reassurance-details-edit-modal';
import { LabeledValue } from '../../../../controls/labeled-value';
import { State } from '../../../../redux/root-reducer';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    benefits: Benefit[];
    contractIndex: number;
    isReadOnly: boolean;
    openModal: OpenModal;
};

const benefitDetails: React.FunctionComponent<Props> = ({
    classes,
    benefits,
    contractIndex,
    isReadOnly,
    openModal,
}: Props) => {
    if (contractIndex < 0 || benefits.length === 0) {
        return null;
    }

    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={ReassuranceEditModal} />,
            modalData: contractIndex,
        });
    };

    return (
        <React.Fragment>
            <Grid container justify="space-between">
                <Grid item xs={10} lg={11} className={classes.marginTop}>
                    <Typography variant="subtitle2">Benefits</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <Tooltip title="Refer to Reinsurance">
                            <Button
                                id="EditReassuranceDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                {benefits.map((benefit, index) => {
                    return (
                        <Grid container justify="flex-start" key={`${benefit.name}`}>
                            {index > 0 && (
                                <Grid item xs={12}>
                                    <Divider
                                        className={classNames(
                                            classes.marginTop,
                                            classes.marginBottom
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{benefit.name}</Typography>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Sum Assured"
                                    value={formatCurrency(benefit.benefitAmount)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Premium Amount"
                                    value={formatCurrency(benefit.premiumAmount)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Office Premium Amount"
                                    value={formatCurrency(benefit.officePremiumAmount)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Load Premium Amount"
                                    value={formatCurrency(benefit.loadPremiumAmount)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Issue Date"
                                    value={getDisplayDate(benefit.startDate)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Expiry Date"
                                    value={getDisplayDate(benefit.endDate)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Upgrade Date"
                                    value={getDisplayDate(benefit.upgradeDate)}
                                />
                            </Grid>
                            <ReassuranceDetailsView
                                reassuranceDetails={benefit.reassuranceDetails || []}
                            />
                        </Grid>
                    );
                })}
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});
const mapDispatchToProps = {
    openModal: openModalAction,
};

export const BenefitDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(benefitDetails));
