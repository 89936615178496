import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import URLSearchParams from '@ungap/url-search-params';
import { push as setLocationAction } from 'connected-react-router';
import { connect } from 'react-redux';
import styles, { StyledComponent } from '../../styles/styles';
import { SubmitForm, submitFormAction } from '../../workflow-navigation/actions';
import { LoadClaim, loadClaimAction } from '../actions/load-claim';
import { SetReference, setReferenceAction } from '../actions/set-reference';
import { setReadOnlyAction, SetReadOnly } from '../actions/set-read-only';

type Props = StyledComponent & {
    loadClaim: LoadClaim;
    submitForm: SubmitForm;
    setLocation: any;
    setReference: SetReference;
    setReadOnly: SetReadOnly;
};

class LoadClaimViewClass extends React.Component<Props> {
    public componentDidMount() {
        if (window.location && window.location.pathname === '/claim') {
            const params = new URLSearchParams(window.location.search);
            const claimId = params.get('claimId') || '';
            const referenceSource = params.get('referenceSource') || '';
            const referenceId = params.get('referenceId') || '';
            const creationDate = params.get('creationDate') || '';
            this.loadClaim(claimId, referenceSource, referenceId, '/', creationDate);
            return;
        }

        const currentClaimId = sessionStorage.getItem('currentClaimId');
        if (currentClaimId) {
            this.loadClaim(currentClaimId);
            return;
        }

        const referenceSource = sessionStorage.getItem('referenceSource');
        const referenceId = sessionStorage.getItem('referenceId');
        const creationDate = sessionStorage.getItem('creationDate');
        if (referenceSource && referenceId && creationDate) {
            this.loadClaim(undefined, referenceSource, referenceId, '/', creationDate);
            return;
        }

        this.checkReferences();
        this.checkReadOnly();
    }
    public render() {
        return null;
    }

    private loadClaim(
        claimId?: string,
        referenceSource?: string,
        referenceId?: string,
        redirectUri?: string,
        creationDate?: string
    ) {
        const readOnly = sessionStorage.getItem('readOnly') === 'true';
        this.props.submitForm(() =>
            this.props.loadClaim(
                claimId,
                referenceSource,
                referenceId,
                redirectUri,
                creationDate,
                readOnly
            )
        );
    }

    private checkReferences() {
        const referenceSource = sessionStorage.getItem('referenceSource');
        const referenceId = sessionStorage.getItem('referenceId');
        const creationDate = sessionStorage.getItem('creationDate');

        if (referenceSource && referenceId && creationDate) {
            this.props.setReference(referenceSource, referenceId, creationDate);
        }
    }

    private checkReadOnly() {
        this.props.setReadOnly(sessionStorage.getItem('readOnly') === 'true');
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    loadClaim: loadClaimAction,
    submitForm: submitFormAction,
    setLocation: setLocationAction,
    setReference: setReferenceAction,
    setReadOnly: setReadOnlyAction,
};

export const LoadClaimView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LoadClaimViewClass));
