import React from 'react'; // eslint-disable-line

import { Button, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { Section } from '../../../../../../controls/section';
import { AddFormModal } from '../../../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../../../form-modal/actions';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    AddAndSubmitPostUnderwritingChange,
    addAndSubmitPostUnderwritingChangeThunk,
} from './actions/submit-post-underwriting-change';
import { PostUnderwritingChangeForm } from './post-underwriting-change-form';
import { PostUnderwritingChange } from './post-underwriting-change-model';
import { PostUnderwritingChangeView } from './post-underwriting-change-view';
import { ButtonWrapper } from '../../../../../../forms';

interface OwnProps {
    policyIndex: number;
    postUnderwritingChanges: PostUnderwritingChange[];
}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    addAndSubmitPostUnderwritingChange: AddAndSubmitPostUnderwritingChange;
    openAddModal: OpenAddModal;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;

const PostUnderwritingChangeAddFormModal = AddFormModal<PostUnderwritingChange>();
const postUnderwritingChangesView: React.FunctionComponent<Props> = ({
    isReadOnly,
    policyIndex,
    postUnderwritingChanges,
    addAndSubmitPostUnderwritingChange,
    openAddModal,
}: Props) => {
    return (
        <Section
            title="Post Underwriting Changes"
            noChildrenTitle="No Post Underwriting Changes"
            gutterElement={
                isReadOnly
                    ? undefined
                    : addPostUnderwritingChangeButton(
                          policyIndex,
                          addAndSubmitPostUnderwritingChange,
                          openAddModal
                      )
            }
        >
            {postUnderwritingChanges.map((postUnderwritingChange, postUnderwritingChangeIndex) => (
                <PostUnderwritingChangeView
                    policyIndex={policyIndex}
                    postUnderwritingChanges={postUnderwritingChanges}
                    postUnderwritingChangeIndex={postUnderwritingChangeIndex}
                    postUnderwritingChange={postUnderwritingChange}
                    key={`post-underwriting-change-${postUnderwritingChangeIndex}}`}
                />
            ))}
        </Section>
    );
};

const addPostUnderwritingChangeButton = (
    policyIndex: number,
    addAndSubmitPostUnderwritingChange: AddAndSubmitPostUnderwritingChange,
    openAddModal: OpenAddModal
) => {
    const openAddView = () => {
        openAddModal({
            modalContent: (
                <PostUnderwritingChangeAddFormModal
                    blankRecord={{ id: uuid() }}
                    recordDetailView={<PostUnderwritingChangeForm />}
                    addRecord={nonDisclosure =>
                        addAndSubmitPostUnderwritingChange(policyIndex, nonDisclosure)
                    }
                />
            ),
            modalData: {},
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Add Post Underwriting Change">
                <Button id="AddPostUnderwritingChange" color="secondary" onClick={openAddView}>
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addAndSubmitPostUnderwritingChange: (
        policyIndex: number,
        postUnderwritingChange: PostUnderwritingChange
    ) => dispatch(addAndSubmitPostUnderwritingChangeThunk(policyIndex, postUnderwritingChange)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const PostUnderwritingChangesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(postUnderwritingChangesView));
