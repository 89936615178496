import React from 'react'; // eslint-disable-line
import { Grid, Typography } from '@material-ui/core';
import { ReportProblem, CheckCircle, Warning } from '@material-ui/icons';
import { LiabilityStatus } from '../common-types';
import { ClaimLiability } from '../../assessment-details-reducer';
import { ClaimType } from '../../../../claim/claim-types';
interface Props {
    claimLiability?: ClaimLiability;
    isCireRan?: boolean;
    claimType?: ClaimType;
    isOverriden?: boolean;
}

export const LiabilityStatusView: React.FunctionComponent<Props> = ({
    claimLiability,
    isCireRan,
    claimType,
    isOverriden,
}: Props) => {
    if (!claimLiability) {
        return null;
    }

    return (
        <Grid item container justify="flex-start" style={{ marginTop: '-5px' }}>
            <Typography style={{ color: '#8b92a7' }}>Liability Request : </Typography>

            <Typography
                style={{
                    marginLeft: '5px',
                    fontWeight: 'bold',
                }}
            >
                {claimLiability.status || 'Pending'}
            </Typography>

            {liabilityStatusIcon(claimLiability.status || 'Pending')}

            {claimLiability.status === 'Failed' && (
                <Grid item xs={12}>
                    <Typography style={{ color: ' #8b92a7' }}>
                        {claimLiability.errorMessage}
                    </Typography>
                </Grid>
            )}

            {claimLiability.status === 'Successful' && (
                <React.Fragment>
                    <Grid container item xs={12} justify="flex-start">
                        <Typography style={{ color: '#8b92a7' }}>Liability status : </Typography>
                        <Typography
                            style={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            {claimLiability.movementStatus || 'Pending'}
                        </Typography>
                        {liabilityStatusIcon(claimLiability.movementStatus || 'Pending')}
                    </Grid>

                    {claimLiability.movementStatus === 'Failed' && (
                        <Grid item xs={12}>
                            <Typography style={{ color: '#8b92a7' }}>
                                {claimLiability.movementMessage}
                            </Typography>
                        </Grid>
                    )}
                </React.Fragment>
            )}

            {claimType === ClaimType.Funeral && (
                <Grid container item xs={12} justify="flex-start">
                    <Typography style={{ color: '#8b92a7' }}>
                        Please ensure that you run the CIRE check before capturing a decision
                    </Typography>

                    <Typography
                        style={{
                            marginLeft: '5px',
                            fontWeight: 'bold',
                        }}
                    >
                        {!isOverriden || 'Pending'}
                    </Typography>

                    {checkCire(isCireRan)}
                    <Grid container item xs={12} justify="flex-start">
                        <Typography style={{ color: '#8b92a7' }}>
                            Please ensure that you override CIRE results before capturing a decision
                        </Typography>
                        <Typography
                            style={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            {!isOverriden || 'Pending'}
                        </Typography>
                        {checkCire(!isOverriden)}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );

    function checkCire(cireStatus?: boolean) {
        if (cireStatus) {
            return <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} />;
        } else {
            return <Warning style={{ color: '#ff6c35', marginLeft: '8px' }} />;
        }
    }
};

function liabilityStatusIcon(status: LiabilityStatus) {
    switch (status) {
        case 'Failed':
            return (
                <ReportProblem
                    style={{
                        color: '#d11329',

                        marginLeft: '8px',
                    }}
                />
            );

        case 'Successful':
            return <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} />;
        case 'Manual':
            return <CheckCircle style={{ color: '#ff6c35', marginLeft: '8px' }} />;
        default:
            return <Warning style={{ color: '#ff6c35', marginLeft: '8px' }} />;
    }
}
