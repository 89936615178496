export const Relationships = [
    { value: 'Spouse', label: 'Spouse' },
    { value: 'ExSpouse', label: 'Ex Spouse' },
    { value: 'EstrangedSpouse', label: 'Estranged Spouse' },
    { value: 'MinorChild', label: 'Minor Child (Younger than 18)' },
    { value: 'MajorChild', label: 'Major Child (18 years and older)' },
    { value: 'Relative', label: 'Relative' },
    { value: 'Parent', label: 'Parent' },
    { value: 'LifePartner', label: 'Life Partner' },
    { value: 'Other', label: 'Other' },
];

export const NaturalRelationships = [
    { value: 'Partner', label: 'Partner' },
    { value: 'ExSpouse', label: 'Ex Spouse' },
    { value: 'EstrangedSpouse', label: 'Estranged Spouse' },
    { value: 'MinorChild', label: 'Minor Child (Younger than 18)' },
    { value: 'MajorChild', label: 'Major Child (18 years and older)' },
    { value: 'Husband', label: 'Husband' },
    { value: 'Wife', label: 'Wife' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Sister', label: 'Sister' },
    { value: 'Cousin', label: 'Cousin' },
    { value: 'Child', label: 'Child' },
    { value: 'Son', label: 'Son' },
    { value: 'Daughter', label: 'Daughter' },
    { value: 'Grandparent', label: 'Grandparent' },
    { value: 'Grandchild', label: 'Grandchild' },
    { value: 'Grandson', label: 'Grandson' },
    { value: 'Granddaughter', label: 'Granddaughter' },
    { value: 'Niece', label: 'Niece' },
    { value: 'Nephew', label: 'Nephew' },
    { value: 'Employer', label: 'Employer' },
    { value: 'Father', label: 'Father' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Grandfather', label: 'Grandfather' },
    { value: 'Grandmother', label: 'Grandmother' },
    { value: 'Uncle', label: 'Uncle' },
    { value: 'Aunt', label: 'Aunt' },
    { value: 'Executor', label: 'Executor' },
    { value: 'Other', label: 'Other' },
];

export const OrganisationRelationships = [
    { value: 'Partner', label: 'Partner' },
    { value: 'Employer', label: 'Employer' },
    { value: 'Creditor', label: 'Creditor' },
    { value: 'Lawyer/Attorney', label: 'Lawyer/Attorney' },
    { value: 'FinancialAdvisor', label: 'Financial Advisor' },
    { value: 'Other', label: 'Other' },
];

export const EntityAssigneeRelationships = [
    { value: 'AuthorisedPerson', label: 'Authorised Person' },
    { value: 'Co-Director', label: 'Co-Director' },
    { value: 'Co-Shareholder', label: 'Co-Shareholder' },
    { value: 'Director', label: 'Director' },
    { value: 'FamilyTrust', label: 'Family Trust' },
    { value: 'Founder', label: 'Founder' },
    { value: 'OrganizationPartner', label: 'Organization Partner' },
    { value: 'OwnerOfAnOrganisation', label: 'Owner of an organisation' },
    { value: 'Shareholder', label: 'Shareholder' },
    { value: 'TrustFounder', label: 'Trust Founder' },
    { value: 'TrustFund', label: 'Trust Fund' },
    { value: 'Trust', label: 'Trust' },
    { value: 'Trustee', label: 'Trustee' },
];

export const RelationshipsChildren = [
    { value: 'AdoptedDaughter', label: 'Adopted Daughter' },
    { value: 'AdoptedSon', label: 'Adopted Son' },
    { value: 'Daughter', label: 'Daughter' },
    { value: 'FosterDaughter', label: 'Foster Daughter' },
    { value: 'FosterSon', label: 'Foster Son' },
    { value: 'Goddaughter', label: 'Goddaughter' },
    { value: 'Godson', label: 'Godson' },
    { value: 'Grand-Daughter', label: 'Grand-Daughter' },
    { value: 'Granddaughter-In-Law', label: 'Granddaughter-In-Law' },
    { value: 'Grand-Nephew', label: 'Grand-Nephew' },
    { value: 'Grand-Niece', label: 'Grand-Niece' },
    { value: 'Grand-Son', label: 'Grand-Son' },
    { value: 'Grandson-In-Law', label: 'Grandson-In-Law' },
    { value: 'GreatGranddaughter', label: 'Great Granddaughter' },
    { value: 'GreatGrandson', label: 'Great Grandson' },
    { value: 'Great-Grandnephew', label: 'Great-Grandnephew' },
    { value: 'Great-Nephew', label: 'Great-Nephew' },
    { value: 'Great-Niece', label: 'Great-Niece' },
    { value: 'Nephew', label: 'Nephew' },
    { value: 'Niece', label: 'Niece' },
    { value: 'Son', label: 'Son' },
    { value: 'Step-Daughter', label: 'Step-Daughter' },
    { value: 'Step-Granddaughter', label: 'Step-Granddaughter' },
    { value: 'Step-Grandson', label: 'Step-Grandson' },
    { value: 'Step-Son', label: 'Step-Son' },
];

export const RelationshipsParents = [
    { value: 'AdoptedFather', label: 'Adopted Father' },
    { value: 'AdoptedMother', label: 'Adopted Mother' },
    { value: 'Father', label: 'Father' },
    { value: 'FosterFather', label: 'Foster Father' },
    { value: 'FosterMother', label: 'Foster Mother' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Step-Father', label: 'Step-Father' },
    { value: 'Step-Mother', label: 'Step-Mother' },
];

export const RelationshipsSpouses = [
    { value: 'Wife', label: 'Wife' },
    { value: 'Husband', label: 'Husband' },
];

export const RelationshipsRelatives = [
    { value: 'Aunt', label: 'Aunt' },
    { value: 'AdoptedBrother', label: 'Adopted Brother' },
    { value: 'AdoptedSister', label: 'Adopted Sister' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Brother-In-Law', label: 'Brother-In-Law' },
    { value: 'Cousin', label: 'Cousin' },
    { value: 'Daughter-In-Law', label: 'Daughter-In-Law' },
    { value: 'Father-In-Law', label: 'Father-In-Law' },
    { value: 'FosterBrother', label: 'Foster Brother' },
    { value: 'FosterSister', label: 'Foster Sister' },
    { value: 'Grandfather', label: 'Grandfather' },
    { value: 'Grandmother', label: 'Grandmother' },
    { value: 'Grandnephew', label: 'Grandnephew' },
    { value: 'Half-Brother', label: 'Half-Brother' },
    { value: 'Half-Sister', label: 'Half-Sister' },
    { value: 'Mother-In-Law', label: 'Mother-In-Law' },
    { value: 'Sister', label: 'Sister' },
    { value: 'Sister-In-Law', label: 'Sister-In-Law' },
    { value: 'Son-In-Law', label: 'Son-In-Law' },
    { value: 'Step-Brother', label: 'Step-Brother' },
    { value: 'Step-Grandfather', label: 'Step-Grandfather' },
    { value: 'Step-Grandmother', label: 'Step-Grandmother' },
    { value: 'Step-Sister', label: 'Step-Sister' },
    { value: 'Step-Son', label: 'Step-Son' },
    { value: 'Uncle', label: 'Uncle' },
];

export const RelationshipsPartner = [
    {
        value: 'Partner',
        label: 'Partner',
    },
];

export const RelationshipsExSpouses = [
    { value: 'Ex-Spouse', label: 'Ex-Wife' },
    { value: 'Ex-Spouse', label: 'Ex-Husband' },
];

export const RelationshipsOther = [
    { value: 'CommonLawSpouse', label: 'Common-law Spouse' },
    { value: 'Curator', label: 'Curator' },
    { value: 'Employer', label: 'Employer' },
    { value: 'Executor', label: 'Executor' },
    { value: 'Fiance(Male)', label: 'Fiance (Male)' },
    { value: 'Fiancee(Female)', label: 'Fiancee (Female)' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Guardian', label: 'Guardian' },
    { value: 'Lawyer/attorney', label: 'Lawyer/attorney' },
];

export const AllRelationships = [
    ...NaturalRelationships,
    ...OrganisationRelationships,
    ...EntityAssigneeRelationships,
    ...Relationships,
    ...RelationshipsChildren,
    ...RelationshipsSpouses,
    ...RelationshipsExSpouses,
    ...RelationshipsPartner,
    ...RelationshipsRelatives,
    ...RelationshipsParents,
    ...RelationshipsOther,
];

export function getRelationship(relationship?: string, description?: string) {
    if (relationship === 'Other') {
        return description;
    }
    if (!relationship || relationship === '') {
        return relationship;
    }
    const code = AllRelationships.filter(r => r.value === relationship)[0];
    return code ? code.label : relationship;
}
