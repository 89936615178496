import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { withStyles, Grid, Typography, Paper, Tooltip, Button } from '@material-ui/core';
import { ExpansionPanel } from '../../../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { State } from '../../../../../../redux/root-reducer';
import { getFormDetails } from '../../form-details-reducer';
import { FormDetails } from '../../form-details-reducer';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { ClaimantDetailsForm } from './claimant-details-edit';

interface BaseProps {
    formDetails?: FormDetails;
    disableEditButton?: boolean;
}

interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const ClaimantDetailsViewComponent: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    formDetails,
    disableEditButton,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={ClaimantDetailsForm} />,
        });
    };

    const claimantContactDetails = formDetails?.claimant?.contactDetails || {};
    const claimantPersonalDetails = formDetails?.claimant?.personalDetails || {};

    return (
        <ExpansionPanel name="Claimant details" style={{ marginLeft: -24, marginRight: -24 }}>
            <React.Fragment>
                <Grid container justify="space-between" className={classes.marginTop2}>
                    <Grid container item xs={10} alignItems="center">
                        <Typography
                            style={{ padding: 10 }}
                            className={classes.heading}
                            variant="body1"
                        >
                            Contact person for claim
                        </Typography>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container item xs={12} alignItems="center" justify="flex-end">
                        <Tooltip title="Edit Claimant Details">
                            <Button
                                id="EditPersonalDetails"
                                color="secondary"
                                onClick={edit}
                                disabled={!disableEditButton}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid container justify="flex-start">
                        <Grid item xs={12} container justify="flex-start">
                            <Grid item xs={4} lg={3}>
                                <LabeledValue label="Title" value={claimantPersonalDetails.title} />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="First Name"
                                    value={claimantPersonalDetails.firstName}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Last Name"
                                    value={claimantPersonalDetails.surname}
                                />
                            </Grid>
                            {claimantContactDetails.telephoneNumber && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Telephone Number"
                                        value={claimantContactDetails.telephoneNumber}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Cellphone"
                                    value={claimantContactDetails.cellphoneNumber}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Email Address"
                                    value={claimantContactDetails.emailAddress}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Company Name"
                                    value={claimantPersonalDetails.companyName}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label={'Broker Consultation code'}
                                    value={claimantPersonalDetails.consultantCode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
    openModal: openModalAction,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const ClaimantDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ClaimantDetailsViewComponent));
