import React from 'react'; // eslint-disable-line
import { Grid } from '@material-ui/core';
import { CloseModal, closeModalAction } from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import { StyledComponent } from '../../../../styles/styles';
import { connect } from 'react-redux';
import { FormHeading, FullWidthTextField } from '../../../../forms';
import { addAssessmentDecisionAction } from '../../actions/add-assessment-decision';
import { PolicyAssessmentDetails } from '../../assessment-details-reducer';
import { requiredValidator } from '../../../../forms/validations';
interface OwnProps {
    classes: any;
    readonly assessmentDetails: PolicyAssessmentDetails[];
}
interface DispatchProps {
    closeModal: CloseModal;
    addApprovalToAssessment: any;
    action: boolean;
}

type Props = OwnProps & DispatchProps & StyledComponent;
const ManagerAddComment: React.FunctionComponent<Props> = ({
    classes,
    closeModal,
    action,
}: Props) => {
    return (
        <Grid container spacing={2} justify="flex-start">
            <Grid item xs={12}>
                <FormHeading text="Add Comment" />
            </Grid>
            <Grid item xs={12}>
                <FullWidthTextField
                    label={action ? 'Approval Reason' : 'Rejection Reason'}
                    name="comment"
                    validate={requiredValidator}
                    multiline
                />
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = {
    addApprovalToAssessment: addAssessmentDecisionAction,
    closeModal: closeModalAction,
};

const mapStateToProps = (state: State) => ({
    adUsers: state.assessorDashboard.adUsers,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerAddComment);
