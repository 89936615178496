import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { UnderwritingReferralsView } from './components/underwriting-referrals';
import { getUnderwriting, Underwriting } from './underwriting-reducer';
import { PolicyUnderwritingView } from './policy-underwriting-view';

interface OwnProps {}

interface StateProps {
    underwriting: Underwriting;
}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingView: React.FunctionComponent<Props> = ({ underwriting }: Props) => {
    return (
        <ExpansionPanel name="Underwriting Details">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {underwriting.details.map((policyUnderwritingDetails, policyIndex) => (
                        <PolicyUnderwritingView
                            key={policyUnderwritingDetails.policyDetails.policyNumber}
                            policyIndex={policyIndex}
                            policyUnderwritingDetails={policyUnderwritingDetails}
                        />
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <UnderwritingReferralsView referrals={underwriting.referrals} />
                </Grid>
            </Grid>
        </ExpansionPanel>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    underwriting: getUnderwriting(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingView));
