import { Grid, Tooltip, Typography } from '@material-ui/core';
import { CheckCircle, Warning } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { v4 as uuid } from 'uuid';
import { DocumentStatus } from '../../../../../codes/claim-documents-enums';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { getDisplayDate } from '../../../../../shared-functions';
import { RequiredDocument } from '../reducer';

interface Props {
    documents: RequiredDocument[];
    isElevated?: boolean;
}

export const RequiredDocumentsView: React.FunctionComponent<Props> = ({
    documents,
    isElevated = false,
}: Props) => {
    if (documents.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            {documents.map(document => {
                const key = uuid();
                return (
                    <Grid item xs={isElevated ? 8 : 6} lg={isElevated ? 8 : 4} key={key}>
                        <LabeledValue label={document.name} />
                        <Grid container justify="flex-start" style={{ marginBottom: '12px' }}>
                            <Typography>{getStatusText(document)}</Typography>
                            {getStatusWidget(document.status)}
                        </Grid>
                    </Grid>
                );
            })}
        </React.Fragment>
    );
};

function getStatusText(document: RequiredDocument) {
    return document.status === DocumentStatus.Requested || document.status === DocumentStatus.Waived
        ? `${document.status} ${getTextReason(document.reason)}- ${getDisplayDate(
              document.dateRequested
          )}`
        : document.status;
}
function getTextReason(reason?: string) {
    if (!reason) {
        return '';
    }
    return `- ${reason.split(/(?=[A-Z])/).join(' ')} `;
}
const getStatusWidget = (documentStatus?: string) => {
    switch (documentStatus) {
        case DocumentStatus.Received:
            return (
                <Tooltip title="Received but not verified">
                    <CheckCircle style={{ color: '#00164e', marginLeft: '8px' }} fontSize="small" />
                </Tooltip>
            );
        case DocumentStatus.Verified:
            return (
                <Tooltip title="Received and verified">
                    <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} fontSize="small" />
                </Tooltip>
            );
        case DocumentStatus.Requested:
            return (
                <Tooltip title="Not yet received">
                    <Warning style={{ color: '#ff6c35', marginLeft: '8px' }} fontSize="small" />
                </Tooltip>
            );
        default:
            return null;
    }
};
