import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getClaim } from '../../../../../../claim';
import { State } from '../../../../../../redux/root-reducer';
import { getFullName } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    ClaimantRequirements,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import {
    AddDocumentButton,
    EditDocumentsButton,
    RequiredDocumentsView,
} from '../../../../common/requirements-management/views';
import { Claimant, getFormDetailsFromClaim } from '../../../form-details/form-details-reducer';
import {
    addAndSubmitCliamantsRequirementsAction,
    AddClaimantsRequirementsDocument,
} from '../../action/add-claimant-documents';
import { FuneralRequirements } from '../../reducer';
import { DocumentOwnerType } from '../../../../../../shared-types';

interface DispatchProps {
    addRequiredDocument: AddClaimantsRequirementsDocument;
}
interface StateProps {
    claimants: Claimant[];
    claimantRequirements: ClaimantRequirements[];
}
interface OwnProps {}
type Props = StyledComponent & OwnProps & DispatchProps & StateProps;
const claimantRequirementsView: React.FunctionComponent<Props> = ({
    addRequiredDocument,
    claimants,
    claimantRequirements,
    classes,
}: Props) => {
    if (!claimants || claimants.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container item xs={12} alignItems="center" className={classes.marginTop}>
                <Typography className={classes.heading} variant="body1">
                    Claimant requirements
                </Typography>
            </Grid>
            {claimantRequirements.map((claimantRequirements, index) => {
                const claimant = claimants.find(c => c.id === claimantRequirements.id) || {};
                const claimantName = getFullName(claimant.personalDetails);
                const documents = claimantRequirements.documents;
                const prefix = `claimants[${index}].documents`;
                return (
                    <Grid container justify="flex-start" key={index}>
                        <Grid item xs={8} lg={10}>
                            <Typography variant="h5" style={{ marginTop: '8px' }}>
                                {claimantName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} lg={2} style={{ textAlign: 'right' }}>
                            <EditDocumentsButton
                                documents={documents}
                                name={claimantName}
                                prefix={prefix}
                            />
                            <AddDocumentButton
                                addAction={record => addRequiredDocument(record, index)}
                                documents={documents}
                                documentOwnerType={DocumentOwnerType.Claimant}
                            />
                        </Grid>
                        <RequiredDocumentsView documents={documents} />
                    </Grid>
                );
            })}
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        claimants: getFormDetailsFromClaim(claim).claimants,
        claimantRequirements:
            (getRequirementsFromClaim(claim) as FuneralRequirements).claimants || [],
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addRequiredDocument: (options, index) =>
        dispatch(addAndSubmitCliamantsRequirementsAction(options, index)),
});

export const ClaimantRequirementsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimantRequirementsView));
