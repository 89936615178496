import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../claim/reducers';
import { State } from '../../../../redux/root-reducer';
import styles from '../../../../styles/styles';
import { askSynopsisType, trusteeResolution } from './actions/get-file';
import { ClaimType } from '../../../../claim/claim-types';
import { ButtonWrapper, NonRaDeathWrapper, RaDeathWrapper } from '../../../../forms';

function generateSynopsis({
    classes,
    claimId,
    claimType,
    generateSynopsisAndGetUrl,
    generateTrusteeResolution,
}: any) {
    switch (claimType) {
        case ClaimType.Funeral:
            return null;

        case ClaimType.RADeath:
            return (
                <ButtonWrapper>
                    <RaDeathWrapper>
                        <Grid container justify="flex-end" className={classes.marginTop3}>
                            <Button
                                id="GenerateSynopsis"
                                color="secondary"
                                variant="contained"
                                className={classes.button}
                                onClick={() => generateTrusteeResolution(claimId)}
                            >
                                Generate Trustee Resolution
                            </Button>
                        </Grid>
                    </RaDeathWrapper>
                </ButtonWrapper>
            );

        default:
            return (
                <NonRaDeathWrapper>
                    <ButtonWrapper>
                        <Grid container justify="flex-end" className={classes.marginTop3}>
                            <Button
                                id="GenerateSynopsis"
                                color="secondary"
                                variant="contained"
                                className={classes.button}
                                onClick={() => generateSynopsisAndGetUrl(claimId)}
                            >
                                Generate Synopsis
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                </NonRaDeathWrapper>
            );
    }
}

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);

    return {
        claimId: claim.claimId,
        claimType: claim.claimType,
    };
};

const mapDispatchToProps = {
    generateSynopsisAndGetUrl: askSynopsisType,
    generateTrusteeResolution: trusteeResolution,
};

export const GenerateSynopsis = connect(
    mapStateToProps,

    mapDispatchToProps
)(withStyles(styles)(generateSynopsis));
