import { Action, Reducer } from 'redux';
import { Nullable } from '../../../../shared-types';
import { State } from '../../../../redux/root-reducer';
import { Claim, getClaim, getProperty, getPropertyFromClaim } from '../../../../claim';
import { ClaimType } from '../../../../claim/claim-types';

export interface ClaimDetails {
    readonly currentCauseOfClaim?: string;
    readonly dateOfEvent?: Date;
    readonly dateClaimReported?: Date;
    readonly treatingSpecialistInitials?: string;
    readonly treatingSpecialistName?: string;
    readonly treatingSpecialistSurname?: string;
    readonly treatingSpecialistTelephoneNumber?: string;
    readonly claimCategory?: string;
    readonly claimCategoryDesc?: string;
    readonly medicalConditionType?: string;
    readonly condition?: string;
    readonly majorClaimCause?: string;
    readonly detailedClaimCondition?: string;
    readonly detailedClaimConditionDesc?: string;
    readonly claimCondition?: string;
    readonly claimConditionDesc?: string;
    readonly accidentDetails?: string;
    readonly reportedToPolice?: string;
    readonly caseNumber?: string;
    readonly policeStation?: string;
    readonly currentTreatmentPlan?: string;
    readonly highLevelDescriptionOfTreatmentOther?: string;
    readonly descriptionOfCurrentTreatmentPlan?: string;
    readonly descriptionOfCurrentMedication?: string;
    readonly comments?: string;
}

export const defaultClaimDetails: ClaimDetails = {};
export const claimDetailsReducer: Reducer = (
    state: Nullable<ClaimDetails> = null,
    action: Action
) => {
    return state;
};

export function getClaimDetails(state: State) {
    return getProperty(state, 'claimDetails', defaultClaimDetails);
}

export function getClaimDetailsFromClaim(claim: Claim) {
    return getPropertyFromClaim(claim, 'claimDetails', defaultClaimDetails);
}

export function noCauseOfClaimDetails(state: State) {
    const claim = getClaim(state);
    if (claim.claimType !== ClaimType.Retrenchment) {
        const claimDetails = getClaimDetailsFromClaim(claim);
        if (
            !claimDetails.claimCategory ||
            !claimDetails.claimCondition ||
            !claimDetails.detailedClaimCondition
        ) {
            return true;
        }
    }

    return false;
}
