import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { addScreenToWorkflow } from '../../workflow-navigation/actions/add-screen-to-workflow';
import { Contract, contractsLoaded } from './contracts-loaded';
import { setSelectedClients } from './set-selected-clients';

export interface SelectedItems {
    [selectId: string]: boolean;
}

type ContractSearchReturn = Promise<any>;
export type ContractSearch = (selectedItems: SelectedItems) => ContractSearchReturn;
type ContractSearchAction = (
    selectedItems: SelectedItems
) => ReduxThunkAction<ContractSearchReturn>;
export const contractSearchAction: ContractSearchAction = (selectedItems: SelectedItems) => {
    return async (dispatch, getState) => {
        const clients = getState().initiateClaim.clients || [];
        const selectedClientIds = getIdNumbers(selectedItems);
        const errors = validate(selectedClientIds);
        if (Object.keys(errors).length > 0) {
            return errors;
        }

        dispatch(setSelectedClients(selectedClientIds));
        for (const clientId of selectedClientIds) {
            const client = clients.find(c => c.clientId === clientId);

            if (!client) {
                throw new Error(`Client "${clientId}" was not found in the state`);
            }
            if (client.contracts === undefined) {
                const searchUi = `${env().CLAIMS_API}/api/Personality/${clientId}/Contracts`;
                const response = await axios.get(searchUi);
                if (!response.data || response.data.length <= 0 || isOnlyFuneral(response.data)) {
                    throw new Error(
                        'The client you have searched for cannot be found, has no valid contracts or has no benefits to continue with the claims process'
                    );
                }
                dispatch(contractsLoaded(response.data.map(contractMapper)));
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { ScreenIndex } = require('../../claim-workflow/workflow-provider/screen-index');
        dispatch(addScreenToWorkflow(ScreenIndex.InitiateClaim.ClaimTypeSelection));

        return errors;
    };
};

function contractMapper(rawContract: any): Contract {
    rawContract.inceptionDate = new Date(rawContract.inceptionDate);
    return rawContract;
}

export function getIdNumbers(selectedItems: SelectedItems): string[] {
    const results = Object.keys(selectedItems)
        .filter(key => selectedItems[key] === true && key !== 'selectAll')
        .map(key => key.replace('select', ''));
    return results;
}

function validate(selectedItems: string[]) {
    if (!selectedItems || selectedItems.length < 1) {
        return {
            [FORM_ERROR]: 'Please select at least one item',
        };
    }
    return {};
}

function isOnlyFuneral(contracts: any[]) {
    if (contracts.length > 0) {
        const allClaimTypes = contracts.map(function (v) {
            return v.claimTypes;
        });

        return allClaimTypes.length === 1 && allClaimTypes[0].includes('Funeral');
    }
}
