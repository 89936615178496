import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import {
    DeathClaim,
    getDeathClaim,
} from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { getObjectWarning } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { VOPDView } from '../../../common/vopd/views';
import { ClaimNotesView } from './claim-notes-view';
import { ClaimDetailsView } from './claim-details-view';
import { NonRaDeathWrapper } from '../../../../../forms';
type Props = StyledComponent & {
    deathClaim: DeathClaim;
};

const claimEventDetailsView: React.FunctionComponent<Props> = ({ deathClaim }: Props) => {
    const notifier = getObjectWarning(deathClaim.claimEventDetails.deathClaimDetails, null);

    const vopd = deathClaim.claimEventDetails.vopd;

    return (
        <ExpansionPanel name="Claim Event Details" notifier={notifier}>
            <ClaimDetailsView />
            <NonRaDeathWrapper>
                <VOPDView vopd={vopd} />
                <ClaimNotesView />
            </NonRaDeathWrapper>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    deathClaim: getDeathClaim(state),
});

const mapDispatchToProps = {};

export const ClaimEventDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimEventDetailsView));
