import React, { useState } from 'react'; // eslint-disable-line

import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import * as CKEditor from '@ckeditor/ckeditor5-react';
import { Button, Typography } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import MaskedInput, { maskArray } from 'react-text-mask';
import { raspberry } from '../../../styles/colours';
import { OCR } from '../ocrcomponent/views/ocr';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../../';
import './styles.css';

export type TextFieldTypes =
    | 'text'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'range'
    | 'search'
    | 'tel'
    | 'time'
    | 'url'
    | 'week';

export interface MaskedInputProps {
    mask?: maskArray | ((value: string) => maskArray);
    guide?: boolean;
    placeholderChar?: string;
    keepCharPositions?: boolean;
    pipe?: (
        conformedValue: string,
        config: any
    ) => false | string | { value: string; indexesOfPipedChars: number[] };
    showMask?: boolean;
    showOcr?: boolean;
}
type Props = TextFieldProps &
    FieldRenderProps<any, any> &
    MaskedInputProps & {
        id?: string;
        label?: React.ReactNode;
        hideLabel?: React.ReactNode;
        helperText?: React.ReactNode;
        type?: TextFieldTypes;
        onChange?: (values: Record<string, any>) => void;
    };

export const RichTextField: React.FunctionComponent<Props> = ({
    id,
    input,
    meta,
    label,
    hideLabel,
    helperText,
    onChange,
    mask,
    guide,
    placeholderChar,
    keepCharPositions,
    pipe,
    showMask,
    showOcr = true,
    inputProps,
    InputProps,
    ...rest
}: Props) => {
    const [txtVal, setTxtVal] = useState('');
    const [showPasteButton, setShowPasteButton] = useState(false);
    const { name, value, ...restInput } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const [editor, setEditor] = useState(null);

    // Only use MaskedInput component internally if a Mask is supplied
    if (!!mask) {
        InputProps = {
            ...InputProps,
            inputComponent: TextMask,
        };
    }

    const maskProps = !mask
        ? {}
        : {
              mask,
              guide,
              placeholderChar,
              keepCharPositions,
              pipe,
              showMask,
          };
    inputProps = {
        ...restInput,
        ...inputProps,
        ...maskProps,
    };

    async function pasteIntoEditor() {
        const ed = editor as any;
        if (ed == null) {
            return;
        }
        ed.model.change((writer: any) => {
            writer.insertText(txtVal, ed.model.document.selection.getFirstPosition());
        });
        await navigator.clipboard.writeText(txtVal);
    }

    const fileProcessedCallback = async (contents: string) => {
        setTxtVal(contents);
        setShowPasteButton(true);

        await navigator.clipboard.writeText(contents || txtVal);
    };

    const showErrorColor = (error: boolean) => {
        return error ? raspberry : '#8b92a7';
    };

    return (
        <React.Fragment>
            {!hideLabel && (
                <Typography
                    style={{
                        height: '27px',
                        fontSize: '14px',
                        fontWeight: 'normal' as const,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: '1.93',
                        letterSpacing: 'normal',
                        color: showErrorColor(showError),
                    }}
                >
                    {nameToLabel({ name, label })}
                </Typography>
            )}
            <div className={showError ? 'error' : 'normal'}>
                <CKEditor
                    {...rest}
                    id={getComponentId({ id, name })}
                    helperText={helperText}
                    error={showError}
                    onInit={(editor: any) => {
                        setEditor(editor);
                        editor.ui
                            .getEditableElement()
                            .parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                    }}
                    onChange={(event: any, editor: any) => {
                        if (inputProps != null && inputProps.onChange) {
                            inputProps.onChange(editor.getData());
                            callAllFunctions();
                        }
                    }}
                    editor={DecoupledEditor}
                    config={{
                        toolbar: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            '|',
                            'alignment',
                            '|',
                            'heading',
                            '|',
                            'bulletedList',
                            'numberedList',
                            'insertTable',
                            '|',
                            'fontFamily',
                            'fontSize',
                            'fontColor',
                            'fontBackgroundColor',
                            '|',
                            'undo',
                            'redo',
                        ],
                    }}
                    data={value}
                />
            </div>
            {showPasteButton && (
                <Button color="secondary" onClick={pasteIntoEditor}>
                    Paste
                </Button>
            )}
            {showError && (
                <div style={{ textAlign: 'center', color: raspberry }}>
                    <Typography variant="caption">{errorMessage}</Typography>
                </div>
            )}
            {showOcr && <OCR id={id} name={name} fileProcessed={fileProcessedCallback}></OCR>}
        </React.Fragment>
    );
};

type TextMaskProps = MaskedInputProps &
    any & {
        inputRef?: React.Ref<any> | React.RefObject<any>;
    };

const TextMask: React.FunctionComponent<TextMaskProps> = ({ inputRef, ...rest }: TextMaskProps) => {
    return (
        <MaskedInput
            {...rest}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
        />
    );
};
