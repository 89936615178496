import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { FormHeading, FullWidthRichTextField, FullWidthTextField } from '../../forms';
import { conditionalRequired } from '../../forms/validations';
import styles, { StyledComponent } from '../../styles/styles';
import { RetrospectiveUnderwritingDecisionDetails } from '../reducer';

type Props = StyledComponent;

const retrospectiveUnderwritingView: React.FunctionComponent<Props> = ({ classes }: Props) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Retrospective Underwriting Decision" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <FullWidthRichTextField
                            id="fullDisclosureAtApplicationStage"
                            name="fullDisclosureAtApplicationStage"
                            label="Underwriting Decision (Based on full disclosure at application stage)"
                            helperText="Based on full disclosure at application stage"
                            showOcr={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FullWidthRichTextField
                            id="chiefMedicalOfficerComments"
                            name="chiefMedicalOfficerComments"
                            showOcr={false}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <FullWidthRichTextField
                            name="additionalInformation"
                            label="Additional Information (Information relating to the Underwriting decision)"
                            showOcr={false}
                            helperText="Information relating to the Underwriting decision"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FullWidthTextField
                            id="retrospectiveUnderwriter"
                            name="retrospectiveUnderwriter"
                            validate={conditionalRequired(
                                (values: RetrospectiveUnderwritingDecisionDetails) =>
                                    !!values.fullDisclosureAtApplicationStage ||
                                    !!values.additionalInformation ||
                                    !!values.chiefMedicalOfficerComments
                            )}
                            multiline
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export const RetrospectiveUnderwritingView = withStyles(styles)(retrospectiveUnderwritingView);
