import React from 'react'; // eslint-disable-line
import { Button, Divider, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { getLifeAssuredDetails, Payment } from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import { formatCurrency, getDisplayDate } from '../../../../../shared-functions';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { PreviousPaymentsEditModal } from './previous-payments-edit-modal';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper, NonRaDeathWrapper } from '../../../../../forms';

type Props = StyledComponent & {
    previousPayments?: Nullable<Payment[]>;
    openModal: OpenModal;
    isReadOnly: boolean;
};

const previousPaymentsView: React.FunctionComponent<Props> = ({
    classes,
    previousPayments,
    openModal,
    isReadOnly,
}: Props) => {
    if (!previousPayments || previousPayments.length === 0) {
        return null;
    }

    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={PreviousPaymentsEditModal} />,
        });
    };

    const lifeAsurredPreviousPayments = previousPayments.filter(
        previousPayment => previousPayment.currentLifeAssured
    );

    return (
        <NonRaDeathWrapper>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={3} alignItems="center">
                    <Typography variant="subtitle2">Previous Payments</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid container item xs={3} lg={1} alignItems="center" justify="flex-end">
                        <Tooltip title="Associate Previous Payments With Life Assured">
                            <Button id="EditPersonalDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {lifeAsurredPreviousPayments.length === 0 && (
                            <Typography>
                                No previous payments selected for current life assured
                            </Typography>
                        )}

                        {lifeAsurredPreviousPayments.map((previousPayment, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {index !== 0 && (
                                        <Divider
                                            className={classNames(
                                                classes.marginTop,

                                                classes.marginBottom
                                            )}
                                        />
                                    )}

                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <LabeledValue
                                                label="Policy Number"
                                                value={previousPayment.policyNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <LabeledValue
                                                label="Benefit"
                                                value={previousPayment.benefit}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <LabeledValue
                                                label="Payment Date"
                                                value={getPaymentDate(previousPayment)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <LabeledValue
                                                label="Amount"
                                                value={formatCurrency(previousPayment.amount)}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Paper>
                </Grid>
            </Grid>
        </NonRaDeathWrapper>
    );
};

function getPaymentDate(previousPayment: Payment): string {
    const paymentDate = getDisplayDate(previousPayment.firstPaymentDate);
    if (!previousPayment.lastPaymentDate) {
        return paymentDate;
    }

    return `${paymentDate} - ${getDisplayDate(previousPayment.lastPaymentDate)}`;
}

const mapStateToProps = (state: State) => ({
    previousPayments: getLifeAssuredDetails(state).previousPayments,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const PreviousPaymentsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(previousPaymentsView));
