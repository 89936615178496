import { claimSubmit } from '../../../claim';
import { Claim } from '../../../claim/reducers';
import { updateRecordReduction } from '../../../redux/reductions';
import { ReduxThunkAction } from '../../../redux/redux-actions';
import { BenefitAssessmentDetails, PolicyAssessmentDetails } from '../assessment-details-reducer';

type UpdateBenefitAssessmentReturn = Promise<any>;
export type UpdateBenefitAssessment = (
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
) => UpdateBenefitAssessmentReturn;
type UpdateBenefitAssessmentAction = (
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
) => ReduxThunkAction<UpdateBenefitAssessmentReturn>;
export const updateBenefitAssessmentAction: UpdateBenefitAssessmentAction = (
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, benefitAssessment, policyIndex, benefitIndex)
    );
};

function getUpdatedClaim(
    claim: Claim,
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
): Claim {
    if (!('assessmentDetails' in claim)) {
        return claim;
    }

    const assessmentDetails = getUpdatedAssessmentDetails(
        claim.assessmentDetails as PolicyAssessmentDetails[],
        benefitAssessment,
        policyIndex,
        benefitIndex
    );

    return {
        ...claim,
        assessmentDetails,
    };
}

function getUpdatedAssessmentDetails(
    assessmentDetails: PolicyAssessmentDetails[],
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
): PolicyAssessmentDetails[] {
    const policyAssessmentDetails = {
        ...assessmentDetails[policyIndex],
        benefits: updateRecordReduction(
            assessmentDetails[policyIndex].benefits,
            benefitAssessment,
            benefitIndex
        ),
    };

    return updateRecordReduction(assessmentDetails, policyAssessmentDetails, policyIndex);
}
