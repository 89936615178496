import React from 'react'; // eslint-disable-line
import { Typography, withStyles } from '@material-ui/core';
import { getFormattedDate } from '../../../../../../shared-functions';
import { State } from '../../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import styles from '../../../../../../styles/styles';

const missingInformationContent = ({ modal }: any) => {
    const creationDate = sessionStorage.getItem('creationDate') || '';

    const date = getFormattedDate(creationDate);
    console.log(modal);

    const claimantName = `${
        modal.form?.communicationsTitle ? modal.form?.communicationsTitle.toLowerCase() : ''
    } ${modal.form?.claimantSurname ? modal.form?.claimantSurname : ''}`;

    return (
        <>
            <Typography variant="body1" style={{ marginBottom: 10, whiteSpace: 'pre-line' }}>
                {`Dear ${claimantName}`} <br /> <br /> Thank you for your claim request on{' '}
                {`${date}`}.
                <br /> <br />
                Policy number:{' '}
                {`${
                    modal?.form?.communicationsPolicy ? modal?.form?.communicationsPolicy : ''
                }`}{' '}
                <br /> <br /> We have received your claim and are unable to process it until we
                receive the following documentation:
            </Typography>
            <ul>
                {modal.form?.documents &&
                    modal.form?.documents?.map(
                        (d: {
                            id: React.Key | null | undefined;
                            name:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                        }) => (
                            <li key={d.id}>
                                {' '}
                                <Typography
                                    variant="body1"
                                    style={{ marginBottom: 10, whiteSpace: 'pre-line' }}
                                >
                                    {d.name}
                                </Typography>
                            </li>
                        )
                    )}
            </ul>
            <br />
            <Typography>
                Kindly provide us with the outstanding documentation so that we can fully assess
                your claim. On receipt of all documentation the claim will be finalised within 48
                hours. <br /> <br /> Please note that incomplete details and unclear documents may
                cause delays and may be requested again.
                <br /> <br />
                For further information please mail us at info@liberty.co.za or Contact us: 0860 456
                789/ +27(0)11 558 4871.
            </Typography>
        </>
    );
};

const mapStateToProps = (state: State) => ({
    modal: state.confirmationDialog,
});

export const MissingInformationContent = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(missingInformationContent));
