import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../../confirmation-dialog';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { EditFormModal } from '../../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../../form-modal/actions';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    DeleteAndSubmitPostUnderwritingChange,
    deleteAndSubmitPostUnderwritingChangeThunk,
    UpdateAndSubmitPostUnderwritingChange,
    updateAndSubmitPostUnderwritingChangeThunk,
} from './actions/submit-post-underwriting-change';
import { PostUnderwritingChangeForm } from './post-underwriting-change-form';
import { PostUnderwritingChange } from './post-underwriting-change-model';

interface OwnProps {
    policyIndex: number;
    postUnderwritingChanges: PostUnderwritingChange[];
    postUnderwritingChangeIndex: number;
    postUnderwritingChange: PostUnderwritingChange;
}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    updateAndSubmitPostUnderwritingChange: UpdateAndSubmitPostUnderwritingChange;
    deleteAndSubmitPostUnderwritingChange: DeleteAndSubmitPostUnderwritingChange;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const postUnderwritingChangeView: React.FunctionComponent<Props> = ({
    policyIndex,
    postUnderwritingChanges,
    postUnderwritingChangeIndex,
    postUnderwritingChange,
    updateAndSubmitPostUnderwritingChange,
    deleteAndSubmitPostUnderwritingChange,
    openEditModal,
    openDialog,
    classes,
    isReadOnly,
}: Props) => {
    return (
        <Grid item container className={classes.separatedGridItem} justify="space-between">
            <Grid item container xs={10} lg={11}>
                <Grid item xs={3}>
                    <LabeledValue
                        id="date"
                        label="Date"
                        value={getDisplayDate(postUnderwritingChange.date)}
                    />
                </Grid>
                <Grid item xs={9}>
                    <LabeledValue
                        id="details"
                        label="Details"
                        value={postUnderwritingChange.details}
                    />
                </Grid>
            </Grid>
            {!isReadOnly && (
                <Grid item container xs={2} lg={1}>
                    <Grid item container xs={12} justify="flex-end">
                        {editPostUnderwritingChangeButton(
                            policyIndex,
                            postUnderwritingChanges,
                            postUnderwritingChangeIndex,
                            updateAndSubmitPostUnderwritingChange,
                            openEditModal
                        )}
                    </Grid>
                    <Grid item container xs={12} justify="flex-end">
                        {deletePostUnderwritingChangeButton(
                            policyIndex,
                            postUnderwritingChangeIndex,
                            deleteAndSubmitPostUnderwritingChange,
                            openDialog
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const PostUnderwritingChangeEditFormModal = EditFormModal<PostUnderwritingChange>();
const editPostUnderwritingChangeButton = (
    policyIndex: number,
    postUnderwritingChanges: PostUnderwritingChange[],
    postUnderwritingChangeIndex: number,
    updateAndSubmitPostUnderwritingChange: UpdateAndSubmitPostUnderwritingChange,
    openEditModal: OpenEditModal
) => {
    const openEditView = () => {
        openEditModal({
            modalContent: (
                <PostUnderwritingChangeEditFormModal
                    recordDetailView={<PostUnderwritingChangeForm />}
                    records={postUnderwritingChanges}
                    updateRecord={(postUnderwritingChange, index) =>
                        updateAndSubmitPostUnderwritingChange(
                            policyIndex,
                            index,
                            postUnderwritingChange
                        )
                    }
                />
            ),
            modalData: {
                recordIndex: postUnderwritingChangeIndex,
            },
        });
    };

    return (
        <Tooltip title="Edit Post Underwriting Change">
            <Button id="EditPostUnderwritingChange" color="secondary" onClick={openEditView}>
                <Edit />
            </Button>
        </Tooltip>
    );
};

const deletePostUnderwritingChangeButton = (
    policyIndex: number,
    postUnderwritingChangeIndex: number,
    deleteAndSubmitPostUnderwritingChange: DeleteAndSubmitPostUnderwritingChange,
    openDialog: OpenDialog
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Post Underwriting Change',
                body:
                    'Are you sure you would like to delete the selected post underwriting change?',
            },
            yesAction: {
                action: () =>
                    deleteAndSubmitPostUnderwritingChange(policyIndex, postUnderwritingChangeIndex),
            },
        });
    };

    return (
        <Tooltip title="Delete Post Underwriting Change">
            <Button id="DeletePostUnderwritingChange" color="secondary" onClick={confirmDelete}>
                <Delete />
            </Button>
        </Tooltip>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = state => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    updateAndSubmitPostUnderwritingChange: (
        policyIndex: number,
        recordIndex: number,
        postUnderwritingChange: PostUnderwritingChange
    ) =>
        dispatch(
            updateAndSubmitPostUnderwritingChangeThunk(
                policyIndex,
                recordIndex,
                postUnderwritingChange
            )
        ),
    deleteAndSubmitPostUnderwritingChange: (policyIndex: number, recordIndex: number) =>
        dispatch(deleteAndSubmitPostUnderwritingChangeThunk(policyIndex, recordIndex)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    openDialog: options => dispatch(openDialogAction(options)),
});

export const PostUnderwritingChangeView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(postUnderwritingChangeView));
