import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../../forms';
import { DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { BankingDetailsView } from '../common/banking-details';
import { ContactDetailsView } from '../common/contact-details';
import { GuardianDetailsView } from '../common/guardian-details';
import { PersonalDetailsView } from '../common/personal-details';
import { editFormDetails } from '../edit-form-details';

interface Props extends StyledComponent {
    formDetails: FormDetails;
    hasDeferredContracts: boolean;
    openModal: OpenModal;
    updateFormDetails: UpdateFormDetails;
}

const policyOwner = ({
    classes,
    formDetails,
    hasDeferredContracts,
    openModal,
    updateFormDetails,
}: Props) => {
    const policyOwner = formDetails.policyOwner || {};
    const contactDetails = policyOwner.contactDetails || {};
    const address = policyOwner.addressDetails || {};
    const bankingDetails = policyOwner.bankingDetails || {};
    const guardianDetails = policyOwner.guardianDetails;
    const heading = 'Policy Owner';
    const prefix = 'policyOwner';
    const edit = () => {
        editFormDetails({
            formDetails,
            heading,
            index: 0,
            openModal,
            prefix,
            updateFormDetails,
        });
    };
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={10}>
                <Typography variant="body1" className={classes.heading}>
                    {heading}
                </Typography>
            </Grid>
            {!hasDeferredContracts && (
                <ButtonWrapper>
                    <Grid item xs={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Policy Owner">
                            <Button id="EditPolicyOwner" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            )}
            <PersonalDetailsView claimantDetails={policyOwner} />
            <ContactDetailsView contactDetails={contactDetails} />
            <DisplayAddress label="Policy Owner's Address" address={address} />
            <GuardianDetailsView gaurdianDetails={guardianDetails} />
            <BankingDetailsView
                bankingDetails={bankingDetails}
                beneficiaryId={policyOwner.id}
                heading={heading}
                prefix={prefix}
            />
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const PolicyOwnerView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(policyOwner));
