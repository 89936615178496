import { createStyles, Grid, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../claim';
import { ClaimReference, getReferences } from '../../claim/reducers/claim-reference-reducer';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';
import globalStyles, { StyledComponent } from '../../styles/styles';
import { reuploadDocumentsAction } from '../actions/re-upload-documents';

function styles(theme: Theme) {
    return createStyles({
        ...globalStyles(theme),
        digitalStatement: {
            '&:hover': {
                background: '#214EBF',
                border: '#214EBF',
            },
            cursor: 'pointer',
            padding: '10px',
        },
    });
}

interface Props extends StyledComponent {
    forms: Nullable<string[]>;
    references: ClaimReference[];
    reupload: any;
}

const digitalForm: React.FunctionComponent<Props> = ({
    classes,
    forms,
    references,
    reupload,
}: Props) => {
    if (!forms || forms.length === 0) {
        return null;
    }

    const reference = references[0];
    function goToClaim() {
        window.open(
            `https://claimforms-devdr.liberty.co.za/claim?referenceId=${reference.referenceId}&referenceSource=${reference.referenceSource}`,
            '_blank'
        );
    }

    function reuploadDocuments() {
        reupload(reference.referenceId, reference.referenceSource, forms);
    }

    return (
        <Grid container style={{ marginTop: 'auto' }}>
            <Grid item xs={12}>
                <Tooltip title="View digital claimant statement">
                    <Typography
                        variant="subtitle2"
                        className={classNames(classes.link, classes.digitalStatement)}
                        onClick={goToClaim}
                        noWrap
                    >
                        Claimant Statement
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip title="Re-upload documents submitted with the digital form">
                    <Typography
                        variant="subtitle2"
                        className={classNames(classes.link, classes.digitalStatement)}
                        onClick={reuploadDocuments}
                        noWrap
                    >
                        Re-Upload Form Documents
                    </Typography>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    forms: getClaim(state).forms,
    references: getReferences(state),
});

const mapDispatchToProps = {
    reupload: reuploadDocumentsAction,
};
export const DigitalFormDrawer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(digitalForm));
