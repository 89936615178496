import React from 'react'; // eslint-disable-line
import { PoliceReportView } from '../common/police-report/views/police-report-view';
import { RulesResultsView } from '../common/rules/views/results-view';
import { Synopsis } from '../common/synopsis/synopsis';
import { CommentsView } from './comments/views/comments';
import { DeathCertificationView } from './death-certification/views/death-certification';
import { FormDetailsView } from './form-details/views/form-details';
import { LifeAssuredView } from './life-assured-details/views/life-assured-details';
import { ManageRequirementsView } from './manage-requirements/views/manage-requirements-view';
import { NoticeOfDeathView } from './notice-of-death/notice-of-death';
import { PolicySummaryView } from './policy-details/policy-summary';
import { ButtonCapture } from '../../../forms';
export const FuneralSynopsis = () => {
    return (
        <Synopsis>
            <ManageRequirementsView />
            <LifeAssuredView />
            <PolicySummaryView />
            <FormDetailsView />
            <DeathCertificationView />
            <NoticeOfDeathView />
            <PoliceReportView />
            <RulesResultsView />
            <ButtonCapture>
                <CommentsView />
            </ButtonCapture>
        </Synopsis>
    );
};
