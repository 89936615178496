import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
    ButtonCapture,
    FullWidthCheckBoxField,
    FullWidthTextField,
    GenericForm,
    WhenFieldChanges,
} from '../../../../../forms';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import { UpdateRules, updateRulesAction } from '../actions/override-rules';
import { Rule, RulesResult } from '../rules-reducer';
import { ClaimType } from '../../../../../claim/claim-types';
import { Nullable } from '../../../../../shared-types';
import { conditionalRequired } from '../../../../../forms/validations';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    index: number;
    rules: Rule[];
    updateRules: UpdateRules;
    currentClaimType?: Nullable<ClaimType>;
}

const ResultsRulesForm = GenericForm<RulesResult>();
const resultsRulesModal: React.FunctionComponent<Props> = ({
    classes,
    index,
    rules,
    updateRules,
    currentClaimType,
    ...rest
}: Props) => {
    const onSubmit = (rulesResult: RulesResult) => {
        return updateRules(rulesResult.rules, index);
    };
    return (
        <ResultsRulesForm onSubmit={onSubmit} initialValues={{ rules: rules }} {...rest}>
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="h5">Override Rules</Typography>
                    <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" className={classes.label}>
                        Rule Name
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="subtitle2" className={classes.label}>
                        Rating
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="subtitle2" className={classes.label}>
                        Score
                    </Typography>
                </Grid>
                <Grid item xs={6} container>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2" className={classes.label}>
                            Override
                        </Typography>
                    </Grid>
                    <ButtonCapture>
                        <Grid item xs={10} container justify="space-between">
                            <Typography variant="subtitle2" className={classes.label}>
                                Comment
                            </Typography>
                        </Grid>
                    </ButtonCapture>
                </Grid>
                {rules.map((rule, rsIndex) => {
                    return (
                        <React.Fragment key={`rule-${rsIndex}`}>
                            <Grid item xs={4}>
                                <Typography className={classes.value}>
                                    {rule.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.value}>{rule.rating}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.value}>{rule.scoreValue}</Typography>
                            </Grid>
                            <Grid item xs={6} container>
                                <OverrideView
                                    index={rsIndex}
                                    rating={rule.rating}
                                    currentClaimType={currentClaimType}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </ResultsRulesForm>
    );
};

interface OverrideProps {
    index: number;
    rating?: string;
    currentClaimType: Nullable<ClaimType>;
}
const OverrideView = ({ index, rating, currentClaimType }: OverrideProps) => {
    if (rating === 'Reject') {
        return null;
    }

    function isFuneralClaim() {
        return currentClaimType === ClaimType.Funeral;
    }

    return (
        <React.Fragment>
            <Grid item xs={2}>
                <FullWidthCheckBoxField
                    name={`rules[${index}].override`}
                    style={{ marginTop: '-24px' }}
                    hideLabel
                />
                <WhenFieldChanges
                    field={`rules[${index}].override`}
                    set={`rules[${index}].comment`}
                    to={null}
                />
            </Grid>
            <ButtonCapture>
                <Grid item xs={10}>
                    <Field
                        name={`rules[${index}].override`}
                        subscription={{ value: true }}
                        component={({ input: { value: override } }) => {
                            return (
                                <FullWidthTextField
                                    name={`rules[${index}].comment`}
                                    style={{ marginTop: '-24px' }}
                                    hideLabel
                                    disabled={!override}
                                    validate={conditionalRequired(isFuneralClaim)}
                                />
                            );
                        }}
                    />
                </Grid>
            </ButtonCapture>
        </React.Fragment>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    updateRules: updateRulesAction,
};

export const ResultsRulesModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(resultsRulesModal));
