import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../forms';
import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect, MapStateToProps } from 'react-redux';
import {
    CloseModal,
    OpenAddModal,
    closeModalAction,
    openAddModalAction,
} from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    Decision,
    DecisionModel,
    getRaDeathAssessmentDecisions,
} from '../ra-death-assessment-details-reducer';
import {
    getDisplayDate,
    formatPercent,
    getFullName,
    getCodeDescription,
} from '../../../../shared-functions';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import {
    Dependant,
    getDependants,
    getLinkedBeneficiaries,
    getSpouses,
    getDependantsRelationship,
} from '../../../synopsis/ra-death/dependants/reducer';
import { Code } from '../../../../codes/codes';
import { AuthState } from '../../../../auth/auth-reducer';
import {
    AddAndSubmitAssessmentDecisionRecord,
    addAndSubmitAssessmentDecisionRecordThunk,
} from '../actions/add-assessment-decision';
import { openBeneficiarySplitModal } from '../../../synopsis/ra-death/dependants/views/dependants-beneficiary-split-modal';
import { LabeledValue } from '../../../../controls/labeled-value';
interface OwnProps {
    isPercentageSplit: boolean;
}

interface DispatchProps {
    openAddModal: OpenAddModal;
    closeModal: CloseModal;
    addAssessmentDecision: AddAndSubmitAssessmentDecisionRecord;
}

interface StateProps {
    readonly currentUser: AuthState;
    readonly decisions: Decision[];
    readonly dependants: Dependant[];
    isReadOnly: boolean;
}

type Props = StateProps & DispatchProps & OwnProps & StyledComponent;
const raDeathAssessmentDecisionView: React.FunctionComponent<Props> = ({
    addAssessmentDecision,
    currentUser,
    decisions,
    dependants,
    openAddModal,
    closeModal,
    isReadOnly,
    isPercentageSplit,
}: Props) => {
    const addDecision = () => {
        openBeneficiarySplitModal(
            null,
            decisions,
            dependants,
            closeModal,
            openAddModal,
            AddDependant
        );
    };

    const AddDependant = async (Decision: DecisionModel) => {
        await addAssessmentDecision(Decision);
    };

    return (
        <React.Fragment>
            <Grid container justify="space-between">
                <Grid item xs={10}>
                    {isPercentageSplit && (
                        <Typography variant="subtitle2" style={{ marginTop: 4 }}>
                            Decision / Recommendation
                        </Typography>
                    )}
                </Grid>
                <ButtonWrapper>
                    {!isPercentageSplit && (
                        <Grid item xs={2} container justify="flex-end">
                            <Tooltip title="Edit recommendation">
                                <Button
                                    id="add_assessment_decision"
                                    color="secondary"
                                    onClick={() => addDecision()}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                    {isPercentageSplit && (
                        <Grid item xs={2} container justify="flex-end">
                            <Tooltip title="Edit recommendation">
                                <Button
                                    id="add_assessment_decision"
                                    color="secondary"
                                    onClick={() => addDecision()}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                </ButtonWrapper>
            </Grid>
            <DecisionsView
                decisions={decisions}
                dependants={dependants}
                isPercentageSplit={isPercentageSplit}
            />
        </React.Fragment>
    );
};

interface DecisionsProps {
    decisions: Decision[];
    dependants: Dependant[];
    isPercentageSplit: boolean;
}
const DecisionsView: React.FunctionComponent<DecisionsProps> = ({
    decisions,
    dependants,
    isPercentageSplit,
}: DecisionsProps) => {
    if (decisions.length === 0) {
        return (
            <DecisionDetailsView
                decision={getDefaultDecision(dependants)}
                dependants={dependants}
                current
                isPercentageSplit={isPercentageSplit}
            />
        );
    }
    const currentDecision = decisions[0];
    const otherDecisions: Decision[] = [];
    decisions.forEach((decision, index) => {
        otherDecisions.push(decision);
    });
    return (
        <React.Fragment>
            <div style={{ backgroundColor: '#F0F1F4', width: '103%', marginLeft: -16 }}>
                {isPercentageSplit && (
                    <Grid item xs={12} style={{ marginLeft: 20 }}>
                        <Typography variant="subtitle2">
                            {getDisplayDate(currentDecision.assessmentDate, 'yyyy/MM/dd HH:mm')} |{' '}
                            {currentDecision.assessor} | {currentDecision.assessmentType}
                        </Typography>
                    </Grid>
                )}
            </div>
            <DecisionDetailsView
                decision={currentDecision}
                dependants={dependants}
                current
                isPercentageSplit={isPercentageSplit}
            />
            <DecisionsExpansionView
                decisions={otherDecisions}
                dependants={dependants}
                isPercentageSplit={isPercentageSplit}
            />
        </React.Fragment>
    );
};

const DecisionsExpansionView: React.FunctionComponent<DecisionsProps> = ({
    decisions,
    dependants,
    isPercentageSplit,
}: DecisionsProps) => {
    if (!isPercentageSplit) {
        return null;
    }
    return (
        <React.Fragment>
            {decisions.map((decision, decisionIndex) => {
                return (
                    <ExpansionPanel
                        name="Decision"
                        customHeader={
                            <Typography variant="subtitle2" style={{ marginLeft: 4 }}>
                                {getDisplayDate(decision.assessmentDate, 'yyyy/MM/dd HH:mm')} |{' '}
                                {decision.assessor} | {decision.assessmentType}
                            </Typography>
                        }
                        key={`decision_${decisionIndex}`}
                        style={{ marginLeft: -24, marginRight: -24 }}
                    >
                        <DecisionDetailsView
                            decision={decision}
                            dependants={dependants}
                            isPercentageSplit={isPercentageSplit}
                        />
                    </ExpansionPanel>
                );
            })}
        </React.Fragment>
    );
};

interface DecisionDetailsProps {
    decision: Decision;
    dependants: Dependant[];
    current?: boolean;
    isPercentageSplit: boolean;
}
const DecisionDetailsView: React.FunctionComponent<DecisionDetailsProps> = ({
    current,
    decision,
    dependants,
    isPercentageSplit,
}: DecisionDetailsProps) => {
    let totalAllocation = 0;
    return (
        <>
            <div
                style={{
                    backgroundColor: '#F0F1F4',
                    width: '103%',
                    marginLeft: -16,
                    marginRight: -16,
                    paddingTop: 4,
                    paddingBottom: 4,
                }}
            >
                <Grid container justify="space-between" style={{ marginLeft: 20, marginBottom: 4 }}>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2">Dependant</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="subtitle2">Age</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">Relationship to Life Assured</Typography>
                    </Grid>
                    {isPercentageSplit && (
                        <Grid item xs={3}>
                            <Typography variant="subtitle2">Reason</Typography>
                        </Grid>
                    )}
                    <Grid item xs={2} container justify="flex-end">
                        <Typography variant="subtitle2" style={{ marginRight: 40 }}>
                            {current && isPercentageSplit ? `Current Allocation` : `Allocation`}
                        </Typography>
                        {isPercentageSplit && (
                            <Typography variant="subtitle2" style={{ marginRight: 40 }}>
                                {getDisplayDate(decision.assessmentDate)}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <hr
                            style={{
                                marginRight: 38,
                                marginLeft: -4,
                                marginTop: 4,
                                marginBottom: 4,
                                borderTop: '1px solid #8B92A7',
                            }}
                        ></hr>
                    </Grid>
                </Grid>
                {dependants.map((dependant, index) => {
                    let allocation = 0;
                    if (
                        decision &&
                        decision.percentageSplits &&
                        decision.percentageSplits !== null
                    ) {
                        allocation =
                            decision.percentageSplits.find(d => d.dependantId === dependant.id)
                                ?.allocation || 0;
                    }
                    const spouses = getSpouses(dependants);
                    const { name, age, relationship, childOf } = getDependantDetails(
                        dependant,
                        spouses
                    );

                    totalAllocation += allocation;
                    return (
                        <Grid
                            container
                            justify="space-between"
                            key={`recommendation_${index}`}
                            style={{ marginLeft: 20 }}
                        >
                            <Grid item xs={3}>
                                <Typography>{name}</Typography>
                                {childOf && childOf !== ' ' && (
                                    <Typography style={{ fontSize: '0.75rem', fontWeight: 300 }}>
                                        {childOf}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>{age}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>{relationship}</Typography>
                            </Grid>
                            <Grid item xs={2} container justify="flex-end">
                                <Typography style={{ marginRight: 40 }}>
                                    {formatPercent(allocation / 100)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <hr
                                    style={{
                                        marginRight: 38,
                                        marginLeft: -4,
                                        marginTop: 4,
                                        marginBottom: 4,
                                        borderTop: '1px solid #8B92A7',
                                    }}
                                ></hr>
                            </Grid>
                        </Grid>
                    );
                })}
                <Grid item xs={12} container direction="row">
                    <Grid item xs={6} container justify="flex-start">
                        <Typography style={{ marginLeft: 20, fontWeight: 'bold' }}>
                            Total
                        </Typography>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <Typography style={{ marginRight: 18, fontWeight: 'bold' }}>
                            {formatPercent(totalAllocation / 100)}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div
                style={{
                    width: '103%',
                    marginRight: 38,
                    marginTop: 4,
                }}
            >
                <Grid item xs={12} container direction="row" style={{ flexDirection: 'column' }}>
                    <Grid item xs={6} container justify="flex-start">
                        <Typography style={{ fontWeight: 'bold', marginTop: 20 }}>
                            Reason(s) For Allocation
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="flex-start">
                        <Typography style={{ marginRight: 18, fontWeight: 'normal' }}>
                            <LabeledValue label="" value={decision.allocationReason} isUnsafe />
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

function getDependantDetails(dependant?: Dependant, spouses?: Code<string>[]) {
    if (dependant) {
        if (dependant.dependantType === 'Organisation') {
            const organisationDetails = dependant.organisationDetails || {};
            return {
                name: organisationDetails.companyName || '',
                age: '-',
                relationship: getCodeDescription(
                    'relationship',
                    organisationDetails.relationshipToLifeAssured
                ),
            };
        }
        const personalDetails = dependant.personalDetails || {};
        let childOf = ' ';
        if (dependant.relationship === 'MinorChild') {
            const linkedParents = getLinkedBeneficiaries(spouses || [], dependant.parent);
            if (Array.isArray(linkedParents) && linkedParents.length > 0) {
                childOf = `${getDependantsRelationship(dependant.relationshipDescription)} of ${
                    linkedParents[0]
                }`;
            } else {
                childOf = 'Parent not listed';
            }
        }
        return {
            name: getFullName(personalDetails),
            age: personalDetails.age ? personalDetails.age.toString() : '-',
            relationship: getCodeDescription('relationship', dependant.relationshipDescription),
            childOf,
        };
    }
    return {
        name: '',
        age: '-',
        relationship: '-',
        childOf: ' ',
    };
}

function getDefaultDecision(dependants: Dependant[]) {
    return {
        id: '',
        assessmentType: '',
        assessmentDate: new Date(),
        assessmentSummary: '',
        assessor: '',
        recommendations: dependants.map(dependant => {
            return {
                dependantId: dependant.id,
                reason: '',
                allocation: 0,
            };
        }),
        percentageSplits: dependants.map(dependant => {
            return {
                dependantId: dependant.id,
                reason: '',
                allocation: 0,
            };
        }),
    };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    currentUser: state.auth as AuthState,
    decisions: getRaDeathAssessmentDecisions(state),
    dependants: getDependants(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    addAssessmentDecision: (record: any) => addAndSubmitAssessmentDecisionRecordThunk(record),
    openAddModal: openAddModalAction,
    closeModal: closeModalAction,
};

export const RaDeathAssessmentDecisionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raDeathAssessmentDecisionView));
