import { Grid, withStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import classNames from 'classnames';
import React, { useState } from 'react'; // eslint-disable-line
import Dropzone from 'react-dropzone';
import { env } from '../../../../env';
import styles, { StyledComponent } from '../../../../styles/styles';
import { nameToLabel } from '../../..';
import CloudUploadOutlined from './CloudUploadOutlined.svg';

interface OwnProps {
    id?: string;
    name?: string;
}

type Props = StyledComponent &
    OwnProps & {
        fileProcessed: (contents: string) => void;
    };

const Ocr: React.FunctionComponent<Props> = ({ classes, fileProcessed, id, name }: Props) => {
    const [loading, setLoading] = useState(false);
    const [error, setShowError] = useState({ showError: false, message: '' });
    async function upload(base64Contents: string) {
        setLoading(true);
        const claimsUri = `${env().CLAIMS_API}/api/Utilities/ProcessOCRImage`;
        const result = await axios
            .post(claimsUri, { base64Contents: base64Contents }, {})
            .then(response => {
                setLoading(false);
                if (response.data) {
                    return response.data;
                }
            })
            .catch(error => {
                setLoading(false);
                return error;
            });
        return result;
    }
    async function uploadFileContents(base64Contents: string) {
        const result = await upload(base64Contents);

        if (result.startsWith('One or more errors occurred')) {
            setShowError({
                showError: true,
                message: 'There was a problem extracting the text from this image.',
            });
        } else {
            fileProcessed(result);
        }
    }
    function arrayBufferToBase64(buffer: any) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    return (
        <React.Fragment>
            <Grid>
                <div style={{ textAlign: 'center', marginBottom: '-8px', marginTop: '4px' }}>
                    <Typography variant="caption" className={classes.helperText}>
                        {`Upload ${nameToLabel({ name: name || 'document', label: undefined })}`}
                    </Typography>
                </div>
                <div className={classNames(classes.dropZoneWrapper, { pulse: loading })}>
                    <Dropzone
                        onDrop={async acceptedFiles => {
                            const formatError = 'Only png and jpeg files are supported';
                            if ((acceptedFiles || []).length === 0) {
                                setShowError(state => {
                                    return { showError: true, message: formatError };
                                });
                                return;
                            }
                            if ((acceptedFiles || []).length > 1) {
                                setShowError({
                                    showError: true,
                                    message: 'Please only upload 1 file at a time',
                                });
                                return;
                            }

                            const formData = new FormData();
                            setShowError({ showError: false, message: '' });
                            acceptedFiles.forEach(file => {
                                const GeneralError =
                                    'There was a problem extracting the information from this file';
                                const reader = new FileReader();

                                reader.onabort = () =>
                                    setShowError({
                                        showError: true,
                                        message: GeneralError,
                                    });
                                reader.onerror = () =>
                                    setShowError({
                                        showError: true,
                                        message: GeneralError,
                                    });
                                reader.onload = () => {
                                    const base64String = arrayBufferToBase64(reader.result);
                                    formData.append('File', file);
                                    uploadFileContents(base64String);
                                };
                                reader.readAsArrayBuffer(file);
                            });
                        }}
                        accept={`image/png, image/jpeg`}
                        disabled={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className={classes.dropZoneSection}>
                                <div {...getRootProps()} className={classes.dropZoneInner}>
                                    <input id={id ? id : 'fileUploadId'} {...getInputProps()} />
                                    <div className={classes.dropZoneDiv}>
                                        <p className={classes.dropZoneDropHere}>
                                            Drag and drop file here
                                        </p>
                                    </div>
                                    <div className={classes.dropZoneDiv}>
                                        <img
                                            src={CloudUploadOutlined}
                                            alt="upload"
                                            className={classes.dropZoneCloud}
                                        />
                                    </div>
                                    <div className={classes.dropZoneDiv}>
                                        <p className={classes.dropZoneLink}>or browse</p>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="caption" className={classes.captionText}>
                        Max file size 5mb, File types allowed: .png, .jpeg
                    </Typography>
                </div>
                {error.showError && (
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="caption" className={classes.errorMessage}>
                            {error.message}
                        </Typography>
                    </div>
                )}
            </Grid>
        </React.Fragment>
    );
};

export const OCR = withStyles(styles)(Ocr);
