import { ReducersMapObject } from 'redux';
import {
    claimAuditTrailReducer,
    ClaimChangeSet,
} from '../../../claim-audit-trail/claim-audit-trail-reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import { simplePropertyReducer } from '../../../redux/simple-property-reducer';
import { ContractDetails, Nullable, ClaimStatusEntry, VersionInfo } from '../../../shared-types';
import { ClaimReference, claimReferenceReducer } from '../../reducers/claim-reference-reducer';
import { claimTypeReducer, ClientReference } from '../../reducers/claim-type-reducer';
import { ClaimType } from '../../claim-types';
import { Payment, paymentReducer } from '../../../payment/reducer';
import { RulesResult, rulesReducer } from '../../../assessment/synopsis/common/rules/rules-reducer';
import { Correspondence } from '../../../assessment/synopsis/common/requirements-management/reducer';

export interface BaseClaim {
    readonly claimType?: Nullable<ClaimType>;
    readonly isClaimInitiated?: boolean;
    readonly skipValidation?: boolean;
    readonly version?: Nullable<VersionInfo>;
    readonly hasRisk?: Nullable<boolean>;
    readonly currentStatus?: string;
    readonly claimId: string;
    readonly documentsUploaded: Nullable<boolean>;
    readonly forms?: Nullable<string[]>;
    readonly auditTrail?: Nullable<ClaimChangeSet[]>;
    readonly references?: Nullable<ClaimReference[]>;
    readonly effectiveDate: Date;
    readonly clientReferences: Nullable<ClientReference[]>;
    readonly contracts: ContractDetails[];
    readonly claimStatusEntries: Nullable<ClaimStatusEntry[]>;
    readonly currentStatusEntry: Nullable<ClaimStatusEntry>;
    readonly validationErrors: string;
    readonly payments: Payment[];
    readonly results: Nullable<RulesResult[]>;
    readonly correspondence?: Nullable<Correspondence>;
}

export const initialBaseClaim: BaseClaim = {
    claimType: null,
    documentsUploaded: null,
    version: null,
    hasRisk: null,
    currentStatus: '',
    claimStatusEntries: [],
    currentStatusEntry: null,
    claimId: '',
    forms: null,
    auditTrail: null,
    effectiveDate: new Date(),
    references: null,
    clientReferences: null,
    contracts: [],
    validationErrors: '',
    payments: [],
    results: null,
    isClaimInitiated: false,
    correspondence: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const baseClaimReducers: ReducersMapObject<BaseClaim, any> = {
    hasRisk: simplePropertyReducer(null),
    claimType: claimTypeReducer,
    documentsUploaded: simplePropertyReducer(null),
    version: simplePropertyReducer(null),
    currentStatus: simplePropertyReducer(''),
    claimStatusEntries: simplePropertyReducer([]),
    currentStatusEntry: simplePropertyReducer(null),
    claimId: simplePropertyReducer(''),
    isClaimInitiated: simplePropertyReducer(false),
    forms: simplePropertyReducer(''),
    auditTrail: claimAuditTrailReducer,
    references: claimReferenceReducer,
    effectiveDate: simplePropertyReducer(new Date()),
    clientReferences: simplePropertyReducer(null),
    contracts: simplePropertyReducer([]),
    correspondence: simplePropertyReducer(null),
    validationErrors: simplePropertyReducer(''),
    payments: paymentReducer,
    results: rulesReducer,
};

export const baseClaimReducer = combineReducersWithDefault<BaseClaim>(
    initialBaseClaim,
    baseClaimReducers
);
