import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../../claim';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    ClaimantRequirements,
    Correspondence,
    getCorrespondenceFromClaim,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import { FuneralRequirements } from '../../reducer';
import { Edit } from '@material-ui/icons';
import { OpenAddModal, openAddModalAction } from '../../../../../../form-modal/actions';
import { CommunicationEditView } from './communications-edit-modal';
import { CommunicationsFormModalView } from './communications-form-view';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { getDisplayDate, getDisplayTime } from '../../../../../../shared-functions';
import { MissingInformationContent } from './missing-infomarion-content';
interface DispatchProps {
    openAddModal: OpenAddModal;
    isDocumentRequested?: boolean;
}
interface StateProps {
    claimantRequirements: ClaimantRequirements[];
    correspondence: Correspondence;
    Requirement: FuneralRequirements;
}

type Props = StyledComponent & DispatchProps & StateProps;
const emailCorrespondenceView: React.FunctionComponent<Props> = ({
    classes,
    claimantRequirements,
    correspondence,
    isDocumentRequested,
    Requirement,
    openAddModal,
}: Props) => {
    isDocumentRequested = true;
    Requirement.lifeAssured.documents.forEach(document => {
        if (document.status === 'Requested') {
            isDocumentRequested = false;
        }
    });

    claimantRequirements.forEach(document => {
        document.documents.forEach(doc => {
            if (doc.status === 'Requested') {
                isDocumentRequested = false;
            }
        });
    });

    const policyOwnerDocList = Requirement.policyOwner?.documents.filter(
        (document: any) => document.status === 'Requested'
    );
    if (policyOwnerDocList && policyOwnerDocList.length > 0) {
        isDocumentRequested = false;
    }

    const openCommunicationsModal = () => {
        openAddModal({
            modalContent: (
                <CommunicationsFormModalView
                    title="E-mail Correspondence (request documentation)"
                    previewScreen={() => <MissingInformationContent />}
                    formView={() => (
                        <CommunicationEditView
                            isShowDocumentRequirements={true}
                            isUnderInvestigation={false}
                        />
                    )}
                />
            ),
            modalData: {},
        });
    };

    return (
        <Grid container justify="flex-start" className={classes.marginTop}>
            <Grid container justify="flex-start" className={classes.marginTop}>
                <Grid item xs={8} lg={10}>
                    <Typography className={classes.heading} variant="body1">
                        E-mail Correspondence (missing information)
                    </Typography>
                </Grid>
                <Grid item xs={4} lg={2} className={classes.rightAlign}>
                    <Tooltip title="Edit Correspondence">
                        <Button
                            id="EditEmailCorrespondence"
                            color="secondary"
                            disabled={isDocumentRequested}
                            onClick={() => {
                                openCommunicationsModal();
                            }}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
                {correspondence &&
                    correspondence.missingInformation &&
                    correspondence.missingInformation
                        .slice() // Creating a shallow copy of the array to avoid mutating the original
                        .sort((a, b) => {
                            // Ensure that a.date and b.date are not undefined
                            const dateA = a.date ? new Date(a.date) : null;
                            const dateB = b.date ? new Date(b.date) : null;

                            // Sort in descending order by date
                            if (dateA && dateB) {
                                return dateB.getTime() - dateA.getTime();
                            } else if (dateA) {
                                return -1;
                            } else if (dateB) {
                                return 1;
                            } else {
                                return 0;
                            }
                        })
                        .map((corr, index) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                className={classes.marginTop}
                                container
                                justify="flex-start"
                            >
                                {correspondence.missingInformation && (
                                    <Grid item md={4} xs={12}>
                                        <LabeledValue
                                            label="Claimant Name"
                                            value={corr.claimantName}
                                        />
                                    </Grid>
                                )}
                                {correspondence.missingInformation && (
                                    <Grid item md={4} xs={12}>
                                        <LabeledValue
                                            label="Email Address sent to"
                                            value={corr.to}
                                        />
                                    </Grid>
                                )}
                                {correspondence.missingInformation && (
                                    <Grid item md={4} xs={12}>
                                        <LabeledValue
                                            label="Additional Recipients"
                                            value={corr.ccs}
                                        />
                                    </Grid>
                                )}
                                {correspondence.missingInformation && (
                                    <>
                                        <Grid item md={4} xs={12}>
                                            <LabeledValue
                                                label="Lastest Email sent date"
                                                value={getDisplayDate(corr.date)}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <LabeledValue
                                                label="Time email sent"
                                                value={getDisplayTime(corr.date)}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        ))}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    claimantRequirements:
        (getRequirementsFromClaim(getClaim(state)) as FuneralRequirements).claimants || [],
    correspondence: (getCorrespondenceFromClaim(getClaim(state)) as Correspondence) || {},
    Requirement: (getRequirementsFromClaim(getClaim(state)) as FuneralRequirements) || [],
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
};

export const EmailCorrespondenceView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(emailCorrespondenceView));
