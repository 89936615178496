import { Grid, Icon, Typography, withStyles } from '@material-ui/core';
import { capitalCase, sentenceCase } from 'change-case';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../controls/labeled-value';
import { formatCurrency, formatPercent, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { AssessmentDecision } from '../../assessment-details-reducer';

interface Props extends StyledComponent {
    assessmentDecision: AssessmentDecision;
    automaticBenefitIncrease?: number;
    benefitName: string;
    commencementDate?: Date;
    reassessment: boolean;
    sumAssured?: number;
}

const assessmentDecisionView: React.FunctionComponent<Props> = ({
    benefitName,
    sumAssured,
    assessmentDecision,
    reassessment,
    automaticBenefitIncrease,
    commencementDate,
}: Props) => {
    return (
        <Grid container>
            <Grid item xs={12} container>
                {assessmentDecision.isOverLimit && (
                    <Grid item xs={12}>
                        <Typography style={{ color: 'red' }}>
                            Over authorised payment limit, needs manager approval
                        </Typography>
                    </Grid>
                )}

                <Typography variant="subtitle2">
                    {reassessment ? 'Reassessment' : 'Assessment'} for Benefit -&nbsp;
                    {capitalCase(benefitName)}&nbsp;&nbsp;
                </Typography>
                {assessmentDecision.final && (
                    <React.Fragment>
                        <DecisionIcon decision={assessmentDecision.decision} />
                        <Typography variant="subtitle1" style={{ marginTop: '-4px' }}>
                            &nbsp;&nbsp;{sentenceCase(assessmentDecision.decision || '')}
                        </Typography>
                    </React.Fragment>
                )}
            </Grid>
            {automaticBenefitIncrease && (
                <Grid item xs={3}>
                    <LabeledValue
                        label="Automatic Benefit Increase"
                        value={formatPercent((automaticBenefitIncrease || 0) / 100)}
                    />
                </Grid>
            )}
            {commencementDate && (
                <Grid item xs={3}>
                    <LabeledValue
                        label="Commencement Date"
                        value={getDisplayDate(commencementDate)}
                    />
                </Grid>
            )}
            {assessmentDecision.summary && (
                <Grid item xs={12}>
                    <LabeledValue label="Summary" value={assessmentDecision.summary} isUnsafe />
                </Grid>
            )}
            <Grid item xs={12} container>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Assessment Date"
                        value={getDisplayDate(assessmentDecision.assessmentDate)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Assessor" value={assessmentDecision.assessor} />
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                {assessmentDecision.mapClaim && (
                    <Grid item xs={3}>
                        <LabeledValue label="MAP Claim" value={assessmentDecision.mapClaim} />
                    </Grid>
                )}
                {assessmentDecision.claimsBooster && (
                    <Grid item xs={3}>
                        <LabeledValue
                            label="EAIP Claims Booster"
                            value={assessmentDecision.claimsBooster}
                        />
                    </Grid>
                )}
            </Grid>
            {assessmentDecision.final && (
                <React.Fragment>
                    {assessmentDecision.reason && (
                        <Grid item xs={3}>
                            <LabeledValue label="Reason" value={assessmentDecision.reason} />
                        </Grid>
                    )}
                    {assessmentDecision.claimType && (
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Claim Type"
                                value={capitalCase(assessmentDecision.claimType || '')}
                            />
                        </Grid>
                    )}
                    {assessmentDecision.decision === 'Approve' && (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <LabeledValue
                                    label="Sum Assured"
                                    value={formatCurrency(sumAssured)}
                                />
                            </Grid>
                            {assessmentDecision.effectiveDate && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Effective Date"
                                        value={getDisplayDate(assessmentDecision.effectiveDate)}
                                    />
                                </Grid>
                            )}
                            {assessmentDecision.expiryDate && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Expiry Date"
                                        value={getDisplayDate(assessmentDecision.expiryDate)}
                                    />
                                </Grid>
                            )}
                            {assessmentDecision.paymentBasis && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Payment Basis"
                                        value={capitalCase(assessmentDecision.paymentBasis)}
                                    />
                                </Grid>
                            )}
                            {assessmentDecision.percentagePayable && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Percentage Payable"
                                        value={formatPercent(
                                            assessmentDecision.percentagePayable / 100
                                        )}
                                    />
                                </Grid>
                            )}
                            {assessmentDecision.amountPayable && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Amount Payable"
                                        value={formatCurrency(assessmentDecision.amountPayable)}
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

interface IconProps {
    decision?: string;
}

const DecisionIcon: React.FunctionComponent<IconProps> = ({ decision }: IconProps) => {
    switch (decision) {
        case 'Approve':
            return (
                <Icon style={{ color: 'green' }} fontSize="small">
                    check_circle
                </Icon>
            );
        case 'Decline':
            return (
                <Icon
                    style={{
                        color: 'white',
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        fontSize: 17,
                    }}
                    fontSize="small"
                >
                    close_circle
                </Icon>
            );
        case 'Defer':
            return (
                <Icon style={{ color: 'orange' }} fontSize="small">
                    remove_circle
                </Icon>
            );
        case 'NotApplicable':
            return (
                <Icon style={{ color: 'yellow' }} fontSize="small">
                    not_interested
                </Icon>
            );
        default:
            return null;
    }
};

export const AssessmentDecisionView = withStyles(styles)(assessmentDecisionView);
