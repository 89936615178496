import React from 'react'; // eslint-disable-line
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getDeathClaimEventDetails } from '../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { DeathClaimDetails } from '../../../synopsis/death/claim-event-details/reducer';
import { RaiseLiability, raiseLiabilityAction } from '../../actions/raise-liability';
import { InvestmentPolicyAssessment } from '../death-assessment-details-reducer';
import { AddInvestmentAssessmentDecisionProps } from './investment-assessment-decision-add';
import { InvestmentAssessmentView } from './investment-assessment-details';
import { OpenDialog, openDialogAction } from '../../../../confirmation-dialog';
import { ButtonWrapper } from '../../../../forms';
import { DeathGroupLiabilityStatusView } from '../../common/views/death-liability-status-view';

interface OwnProps {
    investmentPolicyAssessment: InvestmentPolicyAssessment;
    investmentAssessmentDecisionAddView:
        | React.FunctionComponent<AddInvestmentAssessmentDecisionProps>
        | React.ComponentClass<AddInvestmentAssessmentDecisionProps>;
}

interface StateProps {
    claimDetails?: DeathClaimDetails;
    isReadOnly: boolean;
}

interface DispatchProps {
    openAddModal: OpenAddModal;
    raiseLiability: RaiseLiability;
    openDialog: OpenDialog;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const investmentPolicyAssessmentDetailsView: React.FunctionComponent<Props> = ({
    classes,
    claimDetails,
    investmentPolicyAssessment,
    investmentAssessmentDecisionAddView: InvestmentAssessmentDecisionAddView,
    isReadOnly,
    openAddModal,
    openDialog,
    raiseLiability,
}: Props) => {
    const claimLiability = investmentPolicyAssessment.claimLiability || {};

    return (
        <React.Fragment>
            {investmentPolicyAssessment && (
                <React.Fragment key={investmentPolicyAssessment.policyNumber}>
                    <Grid item container justify="flex-start">
                        <Grid item xs={10}>
                            <Typography
                                variant="subtitle2"
                                className={`${classes.marginTop} ${classes.marginBottom}`}
                            >
                                Policy Number - {investmentPolicyAssessment.policyNumber}
                            </Typography>
                        </Grid>
                        <ButtonWrapper>
                            <Grid
                                container
                                item
                                xs={2}
                                justify="flex-end"
                                style={{ paddingRight: 5 }}
                            >
                                <Button
                                    id={'Add_' + investmentPolicyAssessment.policyNumber}
                                    color="secondary"
                                    style={{ padding: 10, textTransform: 'none' }}
                                    onClick={() => addDecision()}
                                >
                                    <AddCircle />
                                    Add decision
                                </Button>
                            </Grid>
                        </ButtonWrapper>
                    </Grid>
                    {switchOn() && (
                        <Grid container justify="space-between">
                            <Grid item container>
                                <Grid item xs={10}>
                                    <DeathGroupLiabilityStatusView
                                        claimLiability={claimLiability}
                                        policyNumber={investmentPolicyAssessment.policyNumber}
                                        claimDetails={claimDetails}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <InvestmentAssessmentView {...investmentPolicyAssessment} />
                </React.Fragment>
            )}
        </React.Fragment>
    );

    function addDecision() {
        if (!claimDetails) {
            openDialog({
                content: {
                    title: 'Error adding decision',
                    body: 'Please capture claim event details before adding a decision',
                },
            });

            return;
        }

        openAddModal({
            modalContent: (
                <InvestmentAssessmentDecisionAddView
                    assessmentDescription={
                        investmentPolicyAssessment.decisions &&
                        investmentPolicyAssessment.decisions.length > 0 &&
                        investmentPolicyAssessment.decisions.filter(d => !!d.final).length > 0
                            ? 'Reassessment'
                            : 'Assessment'
                    }
                    dateOfDeath={claimDetails && claimDetails.dateOfEvent}
                    {...investmentPolicyAssessment}
                />
            ),
            modalData: {},
        });
    }

    function switchOn() {
        return true;
    }
};

const mapStateToProps = (state: State) => ({
    claimDetails: getDeathClaimEventDetails(state).deathClaimDetails,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
    raiseLiability: raiseLiabilityAction,
    openDialog: openDialogAction,
};

export const InvestmentPolicyAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(investmentPolicyAssessmentDetailsView));
