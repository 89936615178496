import React from 'react'; // eslint-disable-line

import { Button, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Section } from '../../../../../../controls/section';
import { AddFormModal } from '../../../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../../../form-modal/actions';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    AddAndSubmitUnderwritingReferral,
    addAndSubmitUnderwritingReferralThunk,
} from './actions/submit-underwriting-referral';
import { UnderwritingReferralForm } from './underwriting-referral-form';
import { UnderwritingReferral } from './underwriting-referral-model';
import { UnderwritingReferralView } from './underwriting-referral-view';
import { ButtonWrapper } from '../../../../../../forms';

interface OwnProps {
    referrals: UnderwritingReferral[];
}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    addAndSubmitUnderwritingReferral: AddAndSubmitUnderwritingReferral;
    openAddModal: OpenAddModal;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingReferralsView: React.FunctionComponent<Props> = ({
    isReadOnly,
    referrals,
    addAndSubmitUnderwritingReferral,
    openAddModal,
}: Props) => {
    referrals = referrals || [];
    return (
        <Section
            title="Referrals"
            noChildrenTitle="No Referrals"
            gutterElement={
                isReadOnly
                    ? undefined
                    : addReferralButton(addAndSubmitUnderwritingReferral, openAddModal)
            }
        >
            {referrals.map((referral, referralIndex) => (
                <UnderwritingReferralView
                    referral={referral}
                    referralIndex={referralIndex}
                    referrals={referrals}
                    key={`underwriting-referral-${referralIndex}}`}
                />
            ))}
        </Section>
    );
};

const UnderwritingReferralAddFormModal = AddFormModal<UnderwritingReferral>();
const addReferralButton = (
    addAndSubmitUnderwritingReferral: AddAndSubmitUnderwritingReferral,
    openAddModal: OpenAddModal
) => {
    const openAddView = () => {
        openAddModal({
            modalContent: (
                <UnderwritingReferralAddFormModal
                    recordDetailView={<UnderwritingReferralForm />}
                    addRecord={referral => addAndSubmitUnderwritingReferral(referral)}
                />
            ),
            modalData: {},
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Add Referral">
                <Button id="AddReferral" color="secondary" onClick={openAddView}>
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addAndSubmitUnderwritingReferral: (referral: UnderwritingReferral) =>
        dispatch(addAndSubmitUnderwritingReferralThunk(referral)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const UnderwritingReferralsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingReferralsView));
