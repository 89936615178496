import { Button, Grid, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { AuthState } from '../../../../auth/auth-reducer';
import { LabeledValue } from '../../../../controls/labeled-value';
import { ModalViewProps, MultiEditFormModal } from '../../../../form-modal';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper, FullWidthRichTextField, WhenFieldChanges } from '../../../../forms';
import { requiredValidator } from '../../../../forms/validations';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate, titleCase } from '../../../../shared-functions';
import {
    UpdateBenefitAssessment,
    updateBenefitAssessmentAction,
} from '../../actions/update-benefit-assessment';
import { BenefitAssessmentDetails } from '../../assessment-details-reducer';

interface Props {
    benefitAssessmentDetails: BenefitAssessmentDetails;
    benefitIndex: number;
    currentUser: AuthState;
    index: number;
    isReadOnly: boolean;
    openModal: OpenModal;
    policyIndex: number;
    updateAssessment: UpdateBenefitAssessment;
}

interface FieldProps {
    currentUser: AuthState;
    name: string;
}
const EditAssessmentField = ({ currentUser, name }: FieldProps) => {
    return (
        <React.Fragment>
            <FullWidthRichTextField
                name={name}
                validate={requiredValidator}
                showOcr={false}
                hideLabel
            />
            <WhenFieldChanges field={name} set={`${name}By`} to={currentUser.name} />
            <WhenFieldChanges field={name} set={`${name}Date`} to={new Date()} />
        </React.Fragment>
    );
};

const EditModalForm = MultiEditFormModal<BenefitAssessmentDetails>();

function editAssessment({
    benefitAssessmentDetails,
    benefitIndex,
    currentUser,
    index,
    isReadOnly,
    openModal,
    policyIndex,
    updateAssessment,
}: Props) {
    const EditModalViews: ModalViewProps[] = [
        {
            name: 'Summary',
            view: <EditAssessmentField name="summary" currentUser={currentUser} />,
        },
        {
            name: 'Opinion',
            view: <EditAssessmentField name="opinion" currentUser={currentUser} />,
        },
    ];
    const edit = () => {
        openModal({
            modalContent: (
                <EditModalForm
                    heading={`Assessment for Benefit - ${titleCase(
                        benefitAssessmentDetails.benefitName
                    )}`}
                    modalViews={EditModalViews}
                    saveAction={values => updateAssessment(values, policyIndex, benefitIndex)}
                />
            ),
            modalData: {
                screenIndex: index,
                initialValues: benefitAssessmentDetails,
            },
        });
    };
    const viewName = EditModalViews[index].name;
    const fieldName = `${viewName}`.toLowerCase();
    return (
        <Grid container>
            <Grid item xs={10}>
                <Typography variant="subtitle2" style={{ marginTop: 10 }}>
                    {viewName}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <ButtonWrapper>
                    <Button
                        style={{ padding: 10, textTransform: 'none' }}
                        color="secondary"
                        onClick={edit}
                    >
                        <Edit style={{ marginRight: 4 }} /> Edit {viewName}
                    </Button>
                </ButtonWrapper>
            </Grid>
            <Grid item xs={12}>
                <LabeledValue label="" value={benefitAssessmentDetails[fieldName]} isUnsafe />
            </Grid>
            <Grid item xs={6}>
                <LabeledValue
                    label={`${viewName} By`}
                    value={benefitAssessmentDetails[`${fieldName}By`]}
                />
            </Grid>
            <Grid item xs={6}>
                <LabeledValue
                    label={`${viewName} Date`}
                    value={getDisplayDate(benefitAssessmentDetails[`${fieldName}Date`])}
                />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateAssessment: updateBenefitAssessmentAction,
};

export const BenefitAssessmentSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(editAssessment);
