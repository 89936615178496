import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Edit, Refresh } from '@material-ui/icons';
import { connect } from 'react-redux';
import { env } from '../../../../../env';
import {
    UpdateNotifications,
    updateNotificationsAction,
} from '../../../../../life-assured-details/actions/update-notifications';
import { getLifeAssuredDetails, Notification } from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ClaimLifeNotificationEditView } from './claim-life-notification-edit-modal';
import { ButtonWrapper } from '../../../../../forms';

type InnerProps = StyledComponent & {
    claimLifeNotifications: Notification[];
    claimLifeNotificationsUpdated?: string;
};

type Props = InnerProps & {
    openModal: OpenModal;
    updateNotifications: UpdateNotifications;
    isReadOnly: boolean;
};

const astuteInformation: React.FunctionComponent<Props> = ({
    claimLifeNotifications,
    claimLifeNotificationsUpdated,
    classes,
    openModal,
    updateNotifications,
    isReadOnly,
}: Props) => {
    const updateLifeClaimsDeclaration = () => {
        openModal({
            modalContent: <FormModalView formView={ClaimLifeNotificationEditView} />,
        });
    };
    let claimNotifications: Notification[] = [];
    let lifeNotifications: Notification[] = [];
    if (claimLifeNotifications) {
        claimNotifications = claimLifeNotifications
            .filter(n => n.notificationType === 'Claim')
            .sort((a, b) =>
                a.dateOfEvent < b.dateOfEvent ? 1 : b.dateOfEvent < a.dateOfEvent ? -1 : 0
            );
        lifeNotifications = claimLifeNotifications
            .filter(n => n.notificationType === 'Life')
            .sort((a, b) =>
                a.notificationId < b.notificationId
                    ? 1
                    : b.notificationId < a.notificationId
                    ? -1
                    : 0
            );
    }

    return (
        <Grid container justify="space-between" className={classes.marginTop2}>
            <Grid container item xs={6} alignItems="center">
                <Typography variant="subtitle2">
                    Life and Claims Registry (Astute Information)
                </Typography>
            </Grid>
            <ButtonWrapper>
                <Grid container item xs={6} alignItems="center" justify="flex-end">
                    <Tooltip title="Refresh Astute Information">
                        <Button
                            id="refreshAstuteInfo"
                            onClick={updateNotifications}
                            style={{ textTransform: 'none' }}
                        >
                            <Refresh color="secondary" />
                            <Typography
                                color="secondary"
                                style={{ textDecoration: 'underline', marginLeft: '4px' }}
                            >
                                Refresh Astute Information
                            </Typography>
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {claimLifeNotifications && claimLifeNotifications.length === 0 ? (
                        <Typography>
                            No existing Life and Claims Registry (Astute Information) found for the
                            life assured.
                        </Typography>
                    ) : (
                        <React.Fragment>
                            <ClaimNotifications
                                claimLifeNotifications={claimNotifications}
                                classes={classes}
                            />
                            <LifeNotifications
                                claimLifeNotifications={lifeNotifications}
                                classes={classes}
                            />
                        </React.Fragment>
                    )}
                    <ButtonWrapper>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classes.marginTop}
                                container
                                justify="flex-end"
                            >
                                <Tooltip title="Add Astute Information">
                                    <Button
                                        id="AddAstuteInformation"
                                        onClick={() => openLifeClaimsRegistry('Claim')}
                                        style={{ textTransform: 'none' }}
                                    >
                                        <Grid container justify="flex-start">
                                            <AddCircle color="secondary" />
                                            <Typography
                                                variant="subtitle2"
                                                style={{ marginLeft: 4, textAlign: 'left' }}
                                            >
                                                Update Life and Claims Registry (Astute Information)
                                            </Typography>
                                        </Grid>
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                                <LabeledValue
                                    label="Life and Claims Registry Updated"
                                    value={claimLifeNotificationsUpdated}
                                />
                            </Grid>
                            <Grid item xs={6} container justify="flex-end">
                                <Tooltip title="Update Life and Claims Declaration">
                                    <Button
                                        id="LifeAndClaimsDeclaration"
                                        color="secondary"
                                        onClick={updateLifeClaimsDeclaration}
                                    >
                                        <Edit />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ButtonWrapper>
                </Paper>
            </Grid>
        </Grid>
    );
};

const ClaimNotifications: React.FunctionComponent<InnerProps> = ({
    claimLifeNotifications,
    classes,
}: InnerProps) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Claim Information</Typography>
            </Grid>
            {claimLifeNotifications && claimLifeNotifications.length === 0 ? (
                <Typography className={classes.marginTop}>
                    No existing Claim Information found for the life assured.
                </Typography>
            ) : (
                claimLifeNotifications.map((notification, index) => {
                    return (
                        <Grid container key={`${notification.friendlyId}${index}`}>
                            <Grid item xs={2}>
                                <LabeledValue
                                    label="Notification ID"
                                    value={notification.notificationId}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LabeledValue
                                    label="Registration Date"
                                    value={getDisplayDate(notification.dateOfEvent)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LabeledValue
                                    label="Claim Type"
                                    value={claimTypeDescription(notification.claimType)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <LabeledValue
                                    label="Claim Reason Description"
                                    value={
                                        notification.notificationReason &&
                                        notification.notificationReason.map(
                                            reason => `${reason.reasonId} - ${reason.reason}`
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <LabeledValue label="Life Office" value={notification.officeText} />
                            </Grid>
                            <Grid item xs={2}>
                                <LabeledValue
                                    label="LOA NUMBER"
                                    value={notification.notificationId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Notes"
                                    value={
                                        notification.notificationNotes &&
                                        notification.notificationNotes.map(note => note.notes)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider className={classes.marginTop} />
                            </Grid>
                        </Grid>
                    );
                })
            )}
        </Grid>
    );
};

const LifeNotifications: React.FunctionComponent<InnerProps> = ({
    claimLifeNotifications,
    classes,
}: InnerProps) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Life Information
                </Typography>
            </Grid>
            {claimLifeNotifications && claimLifeNotifications.length === 0 ? (
                <Typography className={classes.marginTop}>
                    No existing Life Information found for the life assured.
                </Typography>
            ) : (
                claimLifeNotifications.map((notification, index) => {
                    return (
                        <Grid container key={`${notification.friendlyId}${index}`}>
                            <Grid item xs={2}>
                                <LabeledValue
                                    label="Policy / Member Number"
                                    value={notification.notificationPolicy.policyMemberNumber}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LabeledValue
                                    label="Policy Inception Date"
                                    value={getDisplayDate(
                                        notification.notificationPolicy.inceptionDate
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LabeledValue
                                    label="Policy Type"
                                    value={notification.notificationPolicy.policyTypeId}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <LabeledValue
                                    label="Impairment Description"
                                    value={
                                        notification.notificationReason &&
                                        notification.notificationReason.map(
                                            reason => `${reason.reasonId} - ${reason.reason}`
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <LabeledValue label="Life Office" value={notification.officeText} />
                            </Grid>
                            <Grid item xs={1}>
                                <LabeledValue
                                    label="LOA NUMBER"
                                    value={notification.notificationId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Notes"
                                    value={
                                        notification.notificationNotes &&
                                        notification.notificationNotes.map(note => note.notes)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider className={classes.marginTop} />
                            </Grid>
                        </Grid>
                    );
                })
            )}
        </Grid>
    );
};

function claimTypeDescription(claimType?: string) {
    switch (claimType) {
        case 'Death':
            return 'Death Claim';
        case 'Disease':
            return 'Dread Disease Claim';
        case 'Disability':
            return 'Disability Claim';
        case 'Medical':
            return 'Medical Claim/Hospital Plan';
        default:
            return undefined;
    }
}

function openLifeClaimsRegistry(notificationType: string) {
    const lifeClaimsRegistryUrl = `${env().LifeClaimsRegistry}${notificationType}`;
    window.open(lifeClaimsRegistryUrl);
}

const mapStateToProps = (state: State) => {
    const lifeAssured = getLifeAssuredDetails(state);
    return {
        claimLifeNotifications: lifeAssured.claimLifeNotifications,
        claimLifeNotificationsUpdated: lifeAssured.claimLifeNotificationsUpdated,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps = {
    openModal: openModalAction,
    updateNotifications: updateNotificationsAction,
};

export const AstuteInformation = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(astuteInformation));
