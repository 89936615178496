import React from 'react'; // eslint-disable-line

import { capitalize, endsWith, startCase, upperCase } from 'lodash';
import parseName from '../parse-name/index';

interface Props {
    name: string;
    label?: React.ReactNode;
    prefix?: string;
    suffix?: string;
}

export function nameToLabel({ name, label, prefix = '', suffix = '' }: Props): React.ReactNode {
    const _label = parseLabel(label);
    if (_label) {
        return _label;
    }

    return prefix + formatWords(startCase(parseName(name))) + suffix;
}

export function nameToLabelWithError({
    name,
    label,
    prefix = '',
    suffix = '',
}: {
    name: string;
    label?: string;
    prefix?: string;
    suffix?: string;
}): string {
    const _label = parseLabelWithErrorMessage(label);
    if (_label) {
        return _label;
    }

    const _name = parseName(name);
    const words = startCase(_name).split(' ');

    for (let i = 0; i < words.length - 1; i++) {
        if (endsWith(words[i], 's')) {
            const word = words[i];
            if (shouldGetApostrophe(word)) {
                words[i] = word.substr(0, word.length - 1) + "'s";
            }
        }
    }

    return prefix + words.join(' ') + suffix;
}

function parseLabelWithErrorMessage(label?: string) {
    if (label && typeof label === 'string') {
        return formatWords(label);
    }
    return label;
}

function parseLabel(label?: React.ReactNode) {
    if (label && typeof label === 'string') {
        return formatWords(label);
    }
    return label;
}

function formatWords(value: string) {
    const words = capitalize(value).split(' ');
    for (let i = 0; i < words.length; i++) {
        if (endsWith(words[i], 's') && !endsWith(words[i], "'s")) {
            const word = words[i];
            if (shouldGetApostrophe(word)) {
                words[i] = word.substr(0, word.length - 1) + "'s";
            }
        }
        if (
            words[i] === 'Id' ||
            words[i] === 'id' ||
            words[i] === 'rsa' ||
            words[i] === 'Rsa' ||
            words[i] === 'Ra' ||
            words[i] === 'ra' ||
            words[i] === 'po' ||
            words[i] === 'Po' ||
            words[i] === 'Eaip' ||
            words[i] === 'aip' ||
            words[i] === 'Icd' ||
            words[i] === 'Map' ||
            words[i] === 'Pdb' ||
            words[i] === 'Oeb' ||
            words[i] === 'Paye'
        ) {
            words[i] = upperCase(words[i]);
        }
    }

    return words.join(' ');
}
function shouldGetApostrophe(word: string) {
    const wordsToIgnore = [
        'results',
        'reasons',
        'address',
        'details',
        'diagnosis',
        'as',
        'has',
        'is',
        'business',
        'initials',
        'status',
        'basis',
        'terms',
        'conditions',
        'yes',
        'types',
        'sars',
        'savings',
        'was',
        'previous',
        'disputes',
        'remains',
        'dependants',
        'consultations',
        'notes',
        'foetus',
        'this',
        'comments',
        'claims',
        'instructions',
        'expenses',
        'sales',
        'illness',
        'retirement',
        'compass',
    ];
    return wordsToIgnore.indexOf(word.toLowerCase()) < 0;
}
