import { initiateClaimWorkflow } from '../../claim-workflow/workflow-provider/initiate-claim-workflow';
import { FundNames, initialLookups, lookupReducer, Lookups } from '../../lookups/lookups-reducer';
import { combineReducersWithDefault } from '../../redux/combine-reducers-with-default';
import { State } from '../../redux/root-reducer';
import {
    GetFormSubmissionState,
    GetWorkflowState,
} from '../../workflow-navigation/actions/navigate-to-next-screen';
import {
    formSubmissionReducer,
    FormSubmissionState,
    initialFormSubmissionState,
} from '../../workflow-navigation/reducers/fom-submission-reducer';
import {
    initialWorkflowState,
    workflowReducer,
    WorkflowState,
} from '../../workflow-navigation/reducers/workflow-reducer';
import { Claim, claimReducer, initialClaim } from './claim-reducer';
import { deferredContractsReducer } from './deferred-contracts-reducer';
import { claimReadOnlyReducer } from './read-only-reducer';

export interface ClaimSession {
    readonly readOnly: boolean;
    readonly claim: Claim;
    readonly formSubmission: FormSubmissionState;
    readonly lookups: Lookups;
    readonly workflow: WorkflowState;
    readonly fundNames?: FundNames;
    readonly hasDeferredContracts: boolean;
}

export const initialClaimSession = {
    readOnly: false,
    claim: initialClaim,
    formSubmission: initialFormSubmissionState,
    lookups: initialLookups,
    workflow: initialWorkflowState(initiateClaimWorkflow),
    hasDeferredContracts: false,
};

export const claimSessionReducer = combineReducersWithDefault<ClaimSession>(initialClaimSession, {
    readOnly: claimReadOnlyReducer,
    claim: claimReducer,
    formSubmission: formSubmissionReducer,
    lookups: lookupReducer,
    workflow: workflowReducer(initiateClaimWorkflow),
    hasDeferredContracts: deferredContractsReducer,
});

export const getWorkflowState: GetWorkflowState = (state: State) => {
    return state.claimSession.workflow;
};

export const getFormSubmissionState: GetFormSubmissionState = (state: State) => {
    return state.claimSession.formSubmission;
};
