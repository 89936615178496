import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { ButtonWrapper } from '../../../../forms';
import {
    DeathRaiseLiability,
    deathRaiseLiabilityAction,
} from '../../actions/death-raise-liability';
import { RiskPolicyAssessmentDetails } from '../death-assessment-details-reducer';
import { DeathClaimDetails } from '../../../synopsis/death/claim-event-details/reducer';
import { getDeathClaimEventDetails } from '../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { InvestmentPolicyAssessment } from '../death-assessment-details-reducer';

interface OwnProps {
    GroupPolicies: RiskPolicyAssessmentDetails[];
    investmentPolicyAssessments: InvestmentPolicyAssessment[];
}

interface StateProps {
    claimDetails?: DeathClaimDetails;
}
interface DispatchProps {
    deathRaiseLiability: DeathRaiseLiability;
}

type Props = StateProps & OwnProps & DispatchProps & StyledComponent;
const groupLiabilityButtonView: React.FunctionComponent<Props> = ({
    classes,
    deathRaiseLiability,
    GroupPolicies,
    claimDetails,
    investmentPolicyAssessments,
}: Props) => {
    let visibleButton = true;
    GroupPolicies.forEach(benefit => {
        benefit.benefits.forEach(liablity => {
            const claimLiability = liablity.claimLiability || {};
            if (claimLiability.status === 'Pending' && claimDetails && claimDetails.dateOfEvent) {
                visibleButton = false;
            }
        });
    });

    if (investmentPolicyAssessments.length > 0) {
        investmentPolicyAssessments.forEach(compassPolicy => {
            const claimLiability = compassPolicy.claimLiability || {};
            if (claimLiability.status === 'Pending' && claimDetails && claimDetails?.dateOfEvent) {
                visibleButton = false;
            }
        });
    }

    return (
        <ButtonWrapper>
            <Grid
                container
                justify="flex-end"
                className={classes.marginTop3}
                style={{ paddingRight: 10, marginTop: '40px', marginBottom: '-20px' }}
            >
                <Button
                    id="groupLiability"
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                    disabled={visibleButton}
                    onClick={() =>
                        deathRaiseLiability({
                            policies: GroupPolicies,
                            investmentPolicyAssessments: investmentPolicyAssessments,
                            causeOfDeath: claimDetails && claimDetails.causeOfDeath,
                            dateOfDeath: claimDetails && claimDetails.dateOfEvent,
                        })
                    }
                >
                    Raise liability on all selected benefits
                </Button>
            </Grid>
        </ButtonWrapper>
    );
};

const mapStateToProps = (state: State) => ({
    claimDetails: getDeathClaimEventDetails(state).deathClaimDetails,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    deathRaiseLiability: deathRaiseLiabilityAction,
};

export const GroupLiabilityButtonView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(groupLiabilityButtonView));
