import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { ContactDetailsView } from '../../../../contact-details';
import { ClaimContactDetails, getContactDetails } from '../../../../contact-details/reducer';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import ContactDetailsCard from './contact-details-card';
import { isEmpty } from '../../../../forms/validations';
import { getClaim, Claim } from '../../../../claim';
import { ButtonWrapper } from '../../../../forms';
import {
    FormDetails,
    getFormDetails,
} from '../../living-lifestyle/form-details/form-details-reducer';

interface ButtonProps extends StyledComponent {
    openModal: OpenModal;
}
interface InnerProps {
    contactDetails: ClaimContactDetails;
    formDetails?: FormDetails;
}

interface Props extends StyledComponent, InnerProps, ButtonProps {
    claim: Claim;
    isReadOnly: boolean;
}

const contactPersonView: React.FunctionComponent<Props> = ({
    classes,
    contactDetails,
    formDetails,
    openModal,
    claim,
    isReadOnly,
}: Props) => {
    let notifier;
    if (claim.validationErrors && claim.validationErrors.includes('ContactDetails')) {
        notifier = getContactDetailsObjectWarning(contactDetails, claim.validationErrors);
    } else {
        notifier = getContactDetailsObjectWarning(contactDetails, null);
    }
    return (
        <ExpansionPanel name="Contact Details" notifier={notifier}>
            <Grid container spacing={0} justify="flex-start">
                {!isReadOnly && <EditButton classes={classes} openModal={openModal} />}
                <ContactDetails contactDetails={contactDetails} formDetails={formDetails} />
            </Grid>
        </ExpansionPanel>
    );
};

const ContactDetails: React.FunctionComponent<InnerProps> = ({
    contactDetails,
    formDetails,
}: InnerProps) => {
    const contactPersonDetails = {
        ...formDetails?.claimant?.contactDetails,
        title: formDetails?.claimant?.personalDetails?.title,
        firstName: formDetails?.claimant?.personalDetails?.firstName,
        lastName: formDetails?.claimant?.personalDetails?.surname,
    };

    const contact =
        (contactDetails.contactPerson && contactDetails.contactPerson?.sameDetailsAsFa
            ? contactDetails.financialAdviser
            : contactDetails.contactPerson
            ? contactDetails.contactPerson
            : formDetails?.claimant?.policyDetails?.isOwner === 'Yes' ||
              formDetails?.claimant?.policyDetails?.claimingCapacity === 'Relative'
            ? contactPersonDetails
            : contactDetails.contactPerson) || {};

    const financialAdviserContact = {
        telephoneNumber: contactDetails?.financialAdviser?.telephoneNumber
            ? contactDetails.financialAdviser?.telephoneNumber
            : formDetails?.claimant?.contactDetails?.telephoneNumber,
        cellphoneNumber: contactDetails.financialAdviser?.cellphoneNumber
            ? contactDetails.financialAdviser?.cellphoneNumber
            : formDetails?.claimant?.contactDetails?.cellphoneNumber,
        faxNumber: contactDetails.financialAdviser?.faxNumber
            ? contactDetails.financialAdviser?.faxNumber
            : formDetails?.claimant?.contactDetails?.faxNumber,
        emailAddress: contactDetails.financialAdviser?.emailAddress
            ? contactDetails?.financialAdviser?.emailAddress
            : formDetails?.claimant?.contactDetails?.emailAddress,
        preferredMethodOfCommunication: contactDetails.financialAdviser
            ?.preferredMethodOfCommunication
            ? contactDetails.financialAdviser?.preferredMethodOfCommunication
            : formDetails?.claimant?.contactDetails?.preferredMethodOfCommunication,
        initials: contactDetails.financialAdviser?.initials
            ? contactDetails.financialAdviser?.initials
            : formDetails?.claimant?.personalDetails?.initials,
        title: contactDetails.financialAdviser?.title
            ? contactDetails.financialAdviser?.title
            : formDetails?.claimant?.personalDetails?.title,
        firstName: contactDetails.financialAdviser?.firstName
            ? contactDetails.financialAdviser?.firstName
            : formDetails?.claimant?.personalDetails?.firstName,
        lastName: contactDetails.financialAdviser?.lastName
            ? contactDetails.financialAdviser?.lastName
            : formDetails?.claimant?.personalDetails?.surname,
        companyName: contactDetails.financialAdviser?.companyName
            ? contactDetails.financialAdviser?.companyName
            : formDetails?.claimant?.personalDetails?.companyName,
        consultantCode: contactDetails.financialAdviser?.consultantCode
            ? contactDetails.financialAdviser?.consultantCode
            : formDetails?.claimant?.personalDetails?.consultantCode,
    };

    return (
        <Grid container spacing={2} justify="flex-start">
            <ContactDetailsCard contactDetails={contact} />
            <ContactDetailsCard
                contactDetails={
                    formDetails?.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor'
                        ? financialAdviserContact
                        : contactDetails?.financialAdviser || {}
                }
                isAdviser
                isFinancialAdviser={
                    formDetails?.claimant?.policyDetails?.isOwner === 'Yes' ||
                    formDetails?.claimant?.policyDetails?.claimingCapacity === 'Relative'
                }
            />
        </Grid>
    );
};

const EditButton: React.FunctionComponent<ButtonProps> = ({ classes, openModal }: ButtonProps) => {
    return (
        <ButtonWrapper>
            <Grid item xs={12} className={classes.rightAlign} style={{ marginBottom: 0 }}>
                <Tooltip title="Edit Contact Person">
                    <Button
                        id="EditContactPerson"
                        color="secondary"
                        onClick={() => edit(openModal)}
                    >
                        <Edit />
                    </Button>
                </Tooltip>
            </Grid>
        </ButtonWrapper>
    );
};

function edit(openModal: OpenModal) {
    openModal({
        modalContent: <FormModalView formView={ContactDetailsView} />,
    });
}

const mapStateToProps = (state: State) => ({
    contactDetails: getContactDetails(state),
    claim: getClaim(state),
    isReadOnly: state.claimSession.readOnly,
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {
    openModal: openModalAction,
};
export const getContactDetailsObjectWarning = (contactDetails: any, errors: any) => {
    if (
        isEmpty(contactDetails.contactPerson) ||
        (isEmpty(contactDetails.financialAdviser) && errors === null)
    ) {
        return 'Warning';
    } else if (errors !== null) {
        return 'Error';
    }
    return '';
};
export const ContactPersonView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(contactPersonView));
