import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../../confirmation-dialog';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { EditFormModal } from '../../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../../form-modal/actions';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../styles';
import {
    DeleteAndSubmitUnderwritingReferral,
    deleteAndSubmitUnderwritingReferralThunk,
    UpdateAndSubmitUnderwritingReferral,
    updateAndSubmitUnderwritingReferralThunk,
} from './actions/submit-underwriting-referral';
import { UnderwritingReferralForm } from './underwriting-referral-form';
import { UnderwritingReferral } from './underwriting-referral-model';
import { ButtonWrapper } from '../../../../../../forms';

interface OwnProps {
    referrals: UnderwritingReferral[];
    referralIndex: number;
    referral: UnderwritingReferral;
}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    updateAndSubmitUnderwritingReferral: UpdateAndSubmitUnderwritingReferral;
    deleteAndSubmitUnderwritingReferral: DeleteAndSubmitUnderwritingReferral;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingReferralView: React.FunctionComponent<Props> = ({
    referralIndex,
    referral,
    referrals,
    classes,
    updateAndSubmitUnderwritingReferral,
    deleteAndSubmitUnderwritingReferral,
    openEditModal,
    openDialog,
    isReadOnly,
}: Props) => {
    return (
        <Grid item container className={classes.separatedGridItem} justify="space-between">
            <Grid item container xs={10} lg={11}>
                <Grid item xs={6} lg={3}>
                    <LabeledValue id="date" label="Date" value={getDisplayDate(referral.date)} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        id="claimEventDate"
                        label="Claim Event Date"
                        value={getDisplayDate(referral.claimEventDate)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LabeledValue id="reason" label="Reason" value={referral.reason} />
                </Grid>
            </Grid>
            {!isReadOnly && (
                <Grid item container xs={2} lg={1}>
                    <Grid item container xs={12} justify="flex-end">
                        {editReferralButton(
                            referrals,
                            referralIndex,
                            updateAndSubmitUnderwritingReferral,
                            openEditModal
                        )}
                    </Grid>
                    <Grid item container xs={12} justify="flex-end">
                        {deleteReferralButton(
                            referralIndex,
                            deleteAndSubmitUnderwritingReferral,
                            openDialog
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const UnderwritingReferralEditFormModal = EditFormModal<UnderwritingReferral>();
const editReferralButton = (
    referrals: UnderwritingReferral[],
    referralIndex: number,
    updateAndSubmitUnderwritingReferral: UpdateAndSubmitUnderwritingReferral,
    openEditModal: OpenEditModal
) => {
    const openEditView = () => {
        openEditModal({
            modalContent: (
                <UnderwritingReferralEditFormModal
                    recordDetailView={<UnderwritingReferralForm />}
                    records={referrals}
                    updateRecord={(referral, index) =>
                        updateAndSubmitUnderwritingReferral(index, referral)
                    }
                />
            ),
            modalData: {
                recordIndex: referralIndex,
            },
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Edit referral">
                <Button id="EditReferral" color="secondary" onClick={openEditView}>
                    <Edit />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const deleteReferralButton = (
    referralIndex: number,
    deleteAndSubmitUnderwritingReferral: DeleteAndSubmitUnderwritingReferral,
    openDialog: OpenDialog
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete referral',
                body: 'Are you sure you would like to delete the selected referral?',
            },
            yesAction: { action: () => deleteAndSubmitUnderwritingReferral(referralIndex) },
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Delete referral">
                <Button id="DeleteReferral" color="secondary" onClick={confirmDelete}>
                    <Delete />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = state => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    updateAndSubmitUnderwritingReferral: (recordIndex: number, referral: UnderwritingReferral) =>
        dispatch(updateAndSubmitUnderwritingReferralThunk(recordIndex, referral)),
    deleteAndSubmitUnderwritingReferral: (recordIndex: number) =>
        dispatch(deleteAndSubmitUnderwritingReferralThunk(recordIndex)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    openDialog: options => dispatch(openDialogAction(options)),
});

export const UnderwritingReferralView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingReferralView));
