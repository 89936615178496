import React, { PropsWithChildren } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { AuthState } from '../../../auth/auth-reducer';
import { State } from '../../../redux/root-reducer';

interface Props {
    isReadOnly: boolean;
    user: AuthState;
}

const buttonCapture = ({ isReadOnly, children, user }: PropsWithChildren<Props>) => {
    if (isReadOnly || getRole(user)) {
        return null;
    }
    return <React.Fragment>{children}</React.Fragment>;
};

function getRole(user: AuthState) {
    const roles = ['ClaimsCapture'];
    return roles.some(role => user.roles.includes(role));
}

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
    user: state.auth as AuthState,
});
const mapDispatchToProps = {};
export const ButtonCapture = connect(mapStateToProps, mapDispatchToProps)(buttonCapture);
