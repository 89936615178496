import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { PreviousPaymentsView } from './previous-payments';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { PersonalDetailsView } from './personal-details';
import { AstuteInformation } from './astute-information';
import { OriginalDoctorsDetailsView } from './original-doctors-details';
import { LifeAssuredPortfolio } from './life-assured-portfolio';
import { BreakdownOfDuties } from './breakdown-of-duties';
import { getClaim, Claim } from '../../../../../claim/reducers/claim-reducer';
import { State } from '../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import { getObjectWarning } from '../../../../../shared-functions';
import { getLifeAssuredDetails } from '../../../../../life-assured-details/reducer';

type Props = StyledComponent & {
    claim: Claim;
    claimLifeNotificationsUpdated?: string;
};
const lifeAssuredView: React.FunctionComponent<Props> = ({
    claim,
    claimLifeNotificationsUpdated,
}: Props) => {
    let notifier;
    if (claim.validationErrors && claim.validationErrors.includes('LifeAssuredDetails')) {
        notifier = getObjectWarning(claim.validationErrors, claim.validationErrors);
    } else if (claimLifeNotificationsUpdated === 'No') {
        notifier = 'Warning';
    }
    return (
        <ExpansionPanel name="LifeAssuredDetails" notifier={notifier}>
            <PersonalDetailsView />
            <PreviousPaymentsView />
            <AstuteInformation />
            <OriginalDoctorsDetailsView />
            <BreakdownOfDuties />
            <LifeAssuredPortfolio />
        </ExpansionPanel>
    );
};
const mapStateToProps = (state: State) => ({
    claim: getClaim(state),
    claimLifeNotificationsUpdated: getLifeAssuredDetails(state).claimLifeNotificationsUpdated,
});
export const LifeAssuredView = connect(mapStateToProps, {})(withStyles(styles)(lifeAssuredView));
