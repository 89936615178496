import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getDeathClaim } from '../../../claim/reducers/claim-reducers/death-claim-reducer';
import { State } from '../../../redux/root-reducer';
import { ForensicInputView } from '../common/forensic-input';
import { InformationRecordView } from '../common/information-record';
import { MedicalOfficerInputView } from '../common/medical-officer-input';
import { PoliceReportView } from '../common/police-report/views/police-report-view';
import { ReinsuranceInputView } from '../common/reinsurance-input';
import { RetrospectiveUnderwritingDecisionView } from '../common/retrospective-underwriting-decision';
import { Synopsis } from '../common/synopsis/synopsis';
import { UnderwritingView } from '../common/underwriting';
import { ClaimEventDetailsView } from './claim-event-details';
import { ClaimantDetailsView } from './claimant-details/views/claimant-details';
import { ContactDetailsView } from './contact-details';
import { LifeAssuredDetailsView } from './life-assured-details';
import { NoticeOfDeathView } from './notice-of-death/notice-of-death';
import { DeathPolicySummaryView } from './policy-details';
import { RequirementManagementView } from './manage-requirements/views/requirement-management';
import { NonRaDeathWrapper } from '../../../forms';

interface UWProps {
    under3Years: boolean;
}

interface InnerProps extends UWProps {
    hasRisk: boolean;
}

const deathSynopsisView = ({ ...rest }: InnerProps) => {
    return (
        <Synopsis>
            <RequirementManagementView />
            <ClaimantDetailsView />
            <ContactDetailsView />
            <LifeAssuredDetailsView />
            <NonRaDeathWrapper>
                <DeathPolicySummaryView />
            </NonRaDeathWrapper>
            <ClaimEventDetailsView />
            <NonRaDeathWrapper>
                <RiskDeathSynopsis {...rest} />
                <ForensicInputView />
            </NonRaDeathWrapper>
            <NoticeOfDeathView />
            <PoliceReportView />
        </Synopsis>
    );
};

const RiskDeathSynopsis = ({ under3Years, hasRisk }: InnerProps) => {
    if (!hasRisk) {
        return null;
    }

    return (
        <React.Fragment>
            <InformationRecordView />

            <Underwriting under3Years={under3Years} />

            <ReinsuranceInputView />
        </React.Fragment>
    );
};

const Underwriting = ({ under3Years }: UWProps) => {
    if (!under3Years) {
        return null;
    }

    return (
        <React.Fragment>
            <MedicalOfficerInputView />
            <UnderwritingView />
            <RetrospectiveUnderwritingDecisionView />
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getDeathClaim(state);

    return {
        under3Years: claim.hasLifeCoverUnder3Years || false,
        hasRisk: claim.hasRisk || false,
    };
};

export const DeathSynopsis = connect(mapStateToProps, {})(deathSynopsisView);
